import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaClipboardCheck,
  FaPencilAlt,
  FaCalendarAlt,
  FaEnvelope,
  FaPhone,
  FaCompass,
  FaCheckCircle,
  FaHourglassHalf,
  FaInfoCircle,
  FaArrowRight,
  FaLock,
  FaArrowLeft,
} from "react-icons/fa";
import { InfoIcon, ListCheck } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { loadUserById, uiActions } from "../store/ui-slice";
import AnimatedSection from "../components/AnimatedSection";
import { useHttpClient } from "../shared/hooks/http-hook";
import env from "../env";
import JoinTelegramSection from "../components/JoinTelegramSection";

const PersonalAccount = () => {
  const { sendRequest } = useHttpClient();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.ui.user);

  useEffect(() => {
    dispatch(loadUserById(sendRequest, user.id));
  }, []);

  const [showDialog, setShowDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handleStageClick = async (stage) => {
    if (
      stage.id === 1 &&
      (user.formStageStatus === "pending" ||
        user.formStageStatus === "completed")
    ) {
      // --------- FORM STAGE ---------
      try {
        window.open(
          "https://forms.yandex.ru/u/672a49e65d2a06b2e4b9d80b/",
          "_blank"
        );

        await sendRequest(
          env.BASE_URL + `/api/users/${user.id}/updateStages`,
          "PATCH",
          {
            "Content-Type": "application/json",
          },
          JSON.stringify({
            stageType: "form",
            stageStatus: "completed",
            stageApproved: false,
          })
        );

        dispatch(loadUserById(sendRequest, user.id));
      } catch (error) {}
    } else if (
      // --------- TEST STAGE ---------
      stage.id === 2 &&
      (user.testStageStatus === "pending" ||
        user.testStageStatus === "completed") &&
      user.formStageApproved
    ) {
      try {
        window.open(
          "https://www.16personalities.com/ru/test-lichnosti",
          "_blank"
        );

        await sendRequest(
          env.BASE_URL + `/api/users/${user.id}/updateStages`,
          "PATCH",
          {
            "Content-Type": "application/json",
          },
          JSON.stringify({
            stageType: "test",
            stageStatus: "completed",
            stageApproved: false,
          })
        );

        dispatch(loadUserById(sendRequest, user.id));
      } catch (error) {}
    } else if (
      // --------- INTERVIEW STAGE ---------
      stage.id === 3 &&
      user.interviewStageStatus === "pending" &&
      user.testStageApproved
    ) {
      setShowDialog(true);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("ru-RU", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleConfirmInterview = async () => {
    if (selectedDate) {
      console.log("Selected date:", formatDate(selectedDate));

      try {
        await sendRequest(
          env.BASE_URL + `/api/users/${user.id}/updateStages`,
          "PATCH",
          {
            "Content-Type": "application/json",
          },
          JSON.stringify({
            stageType: "interview",
            stageStatus: "completed",
            stageApproved: false,
            interviewDate: formatDate(selectedDate),
          })
        );

        dispatch(loadUserById(sendRequest, user.id));
      } catch (error) {}

      setShowDialog(false);
    }
  };

  const renderCalendar = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date(2024, 11, 22); // December 21, 2024

    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    ).getDay();

    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="p-2"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day
      );
      const isDisabled =
        date <= today ||
        date > endDate ||
        date.getDay() === 0 ||
        date.getDay() === 6;
      days.push(
        <button
          key={day}
          onClick={() => !isDisabled && setSelectedDate(date)}
          className={`p-2 m-1 rounded ${
            isDisabled
              ? "text-gray-400 cursor-not-allowed"
              : selectedDate?.toDateString() === date.toDateString()
              ? "bg-green-500 text-white"
              : "hover:bg-gray-200"
          }`}
          disabled={isDisabled}
        >
          {day}
        </button>
      );
    }

    return days;
  };

  const nextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  const prevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const logoutHandler = async () => {
    dispatch(uiActions.logout());
    window.location.assign("/");
  };

  return (
    <>
      <div className="min-h-screen bg-gray-25 p-4 md:p-12 relative z-10">
        <div
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/authback3.webp')",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-20 z-10"></div>
        </div>
        <div className="max-w-6xl mx-auto bg-white rounded-xl shadow-lg p-4 sm:p-8 relative z-10 opacity-90">
          <AnimatedSection>
            <h1 className="text-2xl lg:text-4xl font-bold text-green-600 flex justify-center items-center">
              Личный кабинет
            </h1>

            <div className="my-8 md: my-12 bg-white rounded-xl">
              <h2 className="text-xl lg:text-2xl font-semibold my-4 md:my-6 text-gray-700 flex items-center">
                <InfoIcon className="w-7 h-7 mr-4 text-left" />
                Информация участника
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-6 text-lg md:text-xl whitespace-nowrap overflow-hidden">
                <p className="flex items-center">
                  <FaUser className="mr-4 text-green-500" />{" "}
                  <p>
                    Имя:{user?.name} {user?.surname}
                  </p>
                </p>
                <p className="flex items-center">
                  <FaCompass className="mr-4 text-green-500" />
                  <p>Сфера: {user?.sphere}</p>
                </p>
                <p className="flex items-center">
                  <FaEnvelope className="mr-4 text-green-500" />{" "}
                  <p>Email: {user?.email}</p>
                </p>
                <p className="flex items-center">
                  <FaPhone className="mr-4 text-green-500" />{" "}
                  <p>Телефон: {user?.phoneNumber}</p>
                </p>
              </div>
            </div>
          </AnimatedSection>

          <AnimatedSection>
            <h2 className="text-xl lg:text-2xl font-semibold my-4 md:my-6 text-gray-700 flex items-center">
              <ListCheck className="w-7 h-7 mr-4 text-left" />
              Этапы отбора
            </h2>
          </AnimatedSection>
          <div className="space-y-4 sm:space-y-6 mb-8 sm:mb-12">
            <AnimatedSection>
              <div
                className={`flex flex-col gap-y-2 md:gap-y-4 items-start p-4 py-6 sm:p-6 rounded-xl cursor-pointer transition-all duration-300 backdrop-filter backdrop-blur-lg relative ${
                  user.formStageStatus === "completed" &&
                  !user.formStageApproved
                    ? "bg-yellow-100"
                    : user.formStageApproved
                    ? "bg-green-100"
                    : "bg-gray-100"
                }`}
                onClick={() => handleStageClick({ id: 1 })}
              >
                <div className="flex items-center w-full sm:w-auto mb-2 sm:mb-0">
                  <FaClipboardCheck className="text-3xl md:text-4xl text-green-600 flex-shrink-0" />
                  <h3 className="ml-3 text-lg md:text-2xl text-left">
                    Заполнение заявки
                  </h3>
                </div>
                <div className="flex items-center w-full sm:w-auto">
                  <p className="text-md  md:text-lg text-gray-600 flex items-center mt-1 sm:mt-0">
                    {user.formStageApproved ? (
                      <>
                        <FaCheckCircle className="mx-1 mr-2 text-green-500 text-xl md:text-3xl" />
                        Выполнено и подтверждено
                      </>
                    ) : user.formStageStatus === "completed" ? (
                      <>
                        <FaHourglassHalf className="mx-1 mr-2 text-yellow-500 text-xl md:text-3xl" />
                        Выполнено, ожидает подтверждения (проверка занимает
                        некоторое время)
                      </>
                    ) : (
                      <>
                        <FaHourglassHalf className="mx-1 mr-2 text-yellow-500 text-xl md:text-3xl" />
                        Ожидает выполнения
                      </>
                    )}
                  </p>
                </div>
                <div className="absolute top-0 right-4 md:right-24 px-2 md:px-4 md:py-1 bg-orange-200 border border-1 border-gray-100 rounded-b-md md:rounded-b-2xl">
                  <p className="text-[9px] sm:text-xs md:text-sm text-gray-800">
                    <span className="hidden md:inline-block">ОТКРЫТО</span> ДО 2
                    ДЕКАБРЯ
                  </p>
                </div>
                <FaArrowRight className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </AnimatedSection>

            <AnimatedSection>
              <div
                className={`flex flex-col gap-y-2 md:gap-y-4 items-start p-4  py-6 sm:p-6 rounded-xl cursor-pointer transition-all duration-300 backdrop-filter backdrop-blur-lg relative ${
                  user.formStageApproved
                    ? user.testStageStatus === "completed" &&
                      !user.testStageApproved
                      ? "bg-yellow-100"
                      : user.testStageApproved
                      ? "bg-green-100"
                      : "bg-gray-100"
                    : "bg-gray-300"
                }`}
                onClick={() =>
                  user.formStageApproved && handleStageClick({ id: 2 })
                }
              >
                <div className="flex items-center w-full sm:w-auto mb-2 sm:mb-0">
                  <FaPencilAlt className="text-3xl md:text-4xl text-green-600 flex-shrink-0" />
                  <h3 className="ml-3 text-lg md:text-2xl text-left">
                    Тест на софт-скиллс
                  </h3>
                </div>
                <div className="flex items-center w-full sm:w-auto">
                  <p className="text-md  md:text-lg text-gray-600 flex items-center mt-1 sm:mt-0">
                    {user.testStageApproved ? (
                      <>
                        <FaCheckCircle className="mx-1 mr-2 text-green-500 text-xl md:text-3xl" />
                        Выполнено и подтверждено
                      </>
                    ) : user.testStageStatus === "completed" ? (
                      <>
                        <FaHourglassHalf className="mx-1 mr-2 text-yellow-500 text-xl md:text-3xl" />
                        Выполнено, ожидает подтверждения (проверка занимает
                        некоторое время)
                      </>
                    ) : user.formStageApproved ? (
                      <>
                        <FaHourglassHalf className="mx-1 mr-2 text-yellow-500 text-xl md:text-3xl" />
                        Ожидает выполнения
                      </>
                    ) : (
                      <>
                        <FaLock className="mr-1 text-gray-500 mr-3 text-2xl md:text-4xl" />
                        Закрыто до подтверждения предыдущего этапа
                      </>
                    )}
                  </p>
                </div>
                <div className="absolute top-0 right-4 md:right-24 px-2 md:px-4 md:py-1 bg-orange-200 border border-1 border-gray-100 rounded-b-md md:rounded-b-2xl">
                  <p className="text-[9px] sm:text-xs md:text-sm text-gray-800">
                    <span className="hidden md:inline-block">ОТКРЫТО</span> ДО 6
                    ДЕКАБРЯ
                  </p>
                </div>
                <FaArrowRight className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </AnimatedSection>

            <AnimatedSection>
              <div
                className={`flex flex-col gap-y-2 md:gap-y-4 items-start p-4  py-6 sm:p-6 rounded-xl cursor-pointer transition-all duration-300 backdrop-filter backdrop-blur-lg relative ${
                  user.testStageApproved
                    ? user.interviewStageStatus === "completed" &&
                      !user.interviewStageApproved
                      ? "bg-yellow-100"
                      : user.interviewStageApproved
                      ? "bg-green-100"
                      : "bg-gray-100"
                    : "bg-gray-300"
                }`}
                onClick={() =>
                  user.testStageApproved && handleStageClick({ id: 3 })
                }
              >
                <div className="flex items-center w-full sm:w-auto mb-2 sm:mb-0">
                  <FaCalendarAlt className="text-3xl md:text-4xl text-green-600 flex-shrink-0" />
                  <h3 className="ml-3 text-lg md:text-2xl text-left">
                    Собеседование
                  </h3>
                </div>
                <div className="flex items-center w-full sm:w-auto">
                  <p className="text-md  md:text-lg text-gray-600 flex items-center mt-1 sm:mt-0">
                    {user.interviewStageStatus === "completed" ? (
                      <>
                        <FaCheckCircle className="mx-1 mr-2 text-green-500 text-xl md:text-3xll" />
                        Выбрана дата: {user.interviewStageDate}
                      </>
                    ) : user.testStageApproved ? (
                      <>
                        <FaHourglassHalf className="mx-1 mr-2 text-yellow-500 text-xl md:text-3xlxl" />
                        Ожидает выбора даты
                      </>
                    ) : (
                      <>
                        <FaLock className="mx-1 mr-2 text-gray-500 text-xl md:text-3xl" />
                        Закрыто до подтверждения предыдущего этапа
                      </>
                    )}
                  </p>
                </div>
                <div className="absolute top-0 right-4 md:right-24 px-2 md:px-4 md:py-1 bg-orange-200 border border-1 border-gray-100 rounded-b-md md:rounded-b-2xl">
                  <p className="text-[9px] sm:text-xs md:text-sm text-gray-800">
                    <span className="hidden md:inline-block">ОТКРЫТО</span> ДО
                    21 ДЕКАБРЯ
                  </p>
                </div>
                <FaArrowRight className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </AnimatedSection>
          </div>

          <AnimatedSection>
            <div className="bg-gray-100 bg-opacity-80 rounded-xl p-4 md:p-8 backdrop-filter backdrop-blur-lg">
              <h2 className="text-md md:text-2xl font-semibold mb-4 md:mb-6 text-gray-700 flex items-center">
                <FaInfoCircle className="mr-4 text-green-600" />
                Информация об этапах отбора
              </h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg md:text-xl font-semibold mb-2 flex items-center">
                    <FaClipboardCheck className="mr-2 text-green-500" />
                    Этап 1: Заполнение заявки
                  </h3>
                  <p className="text-gray-700 text-left text-justify">
                    На этом этапе вам необходимо заполнить подробную анкету на
                    платформе Яндекс.Формы. На основании заполненной анкеты
                    формируется резюме участника с необходимой контактной
                    информацией, достижениями и опытом.
                  </p>
                  <p className="text-gray-700 text-left mt-4">
                    Если ваша заявка слишком долго находится на проверке,
                    пожалуйста, свяжитесь с нами по почте.
                    [Nastavnichestvo01@mail.ru]
                  </p>
                </div>
                <div>
                  <h3 className="text-lg md:text-xl font-semibold mb-2 flex items-center">
                    <FaPencilAlt className="mr-2 text-green-500" />
                    Этап 2: Тест на софт-скиллс
                  </h3>
                  <p className="text-gray-700 text-left text-justify">
                    Второй этап представляет собой онлайн-тест, оценивающий ваши
                    мягкие навыки (софт-скиллс). Тест включает вопросы на
                    коммуникацию, эмпатию, решение проблем и управление
                    временем. Результаты теста помогут нам оценить ваш потенциал
                    как участника.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg md:text-xl font-semibold mb-2 flex items-center">
                    <FaCalendarAlt className="mr-2 text-green-500" />
                    Этап 3: Cобеседование
                  </h3>
                  <p className="text-gray-700 text-left text-justify">
                    Заключительный этап - это личное собеседование с нашими
                    экспертами. Вы сможете выбрать удобное время для проведения
                    интервью. На собеседовании мы обсудим ваш опыт, мотивацию и
                    ответим на все ваши вопросы о программе.
                  </p>
                </div>
              </div>
              <p className="mt-6 text-gray-700 text-left">
                <FaArrowRight className="inline mr-2 text-green-500" />
                После успешного прохождения всех этапов отбора вы получите
                приглашение присоединиться к нашей программе наставничества.
              </p>
            </div>
            <div className="flex justify-end mt-8">
              <button
                className="bg-red-500 text-white px-6 py-3 rounded-lg text-lg  hover:bg-red-600 transition-colors duration-300"
                onClick={logoutHandler}
              >
                Выйти
              </button>
            </div>
          </AnimatedSection>
        </div>

        {showDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
            <div className="bg-white m-2 p-4 md:p-6 rounded-lg max-w-md w-full">
              <h2 className="text-lg md:text-2xl font-bold mb-4">
                Выберите дату собеседования
              </h2>
              <div className="my-2">
                <div className="flex justify-between items-center mb-2">
                  <button onClick={prevMonth} className="p-2">
                    <FaArrowLeft />
                  </button>
                  <span>
                    {currentMonth.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button onClick={nextMonth} className="p-2">
                    <FaArrowRight />
                  </button>
                </div>
                <div className="grid grid-cols-7 gap-1">
                  {["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"].map((day) => (
                    <div key={day} className="text-center font-bold">
                      {day}
                    </div>
                  ))}
                  {renderCalendar()}
                </div>
              </div>
              {selectedDate && (
                <p className="my-4 text-green-600">
                  Выбранная дата: {formatDate(selectedDate)}
                </p>
              )}
              <div className="flex justify-end space-x-2">
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
                  onClick={() => setShowDialog(false)}
                >
                  Отмена
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded"
                  onClick={handleConfirmInterview}
                  disabled={!selectedDate}
                >
                  Подтвердить
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <JoinTelegramSection />
    </>
  );
};

export default PersonalAccount;
