import React from "react";
import { styled, keyframes } from "styled-components";
import {
  Code,
  Server,
  Globe,
  Shield,
  Cpu,
  CheckCircle,
  Database,
  Layers,
  Terminal,
  School,
  University,
  GraduationCap,
} from "lucide-react";
import AnimatedBackground from "../components/AnimatedBackground";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import JoinProjectSection from "../components/JoinProjectSection";
import AnimatedBranchTree from "../components/AnimatedBranchTree";
import Reviews from "../components/Reviews";

const rotate = keyframes`
  from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
  to { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
`;

const scroll = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;

const ScrollingBanner = styled.div`
  background-color: #2563eb;
  color: white;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} 30s linear infinite;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -20px;
  margin-left: -20px;
  animation: ${rotate} 30s linear infinite;
  color: white;
`;

export default function ITProgramming() {
  const itFields = [
    "IT",
    "КИБЕРБЕЗОПАСНОСТЬ",
    // "ИНФОРМАТИКА И ИНФОРМАЦИОННЫЕ ПРОЦЕССЫ",
    "ПРОГРАММИРОВАНИЕ",
    "ТЕЛЕКОММУНИКАЦИЯ И СВЯЗЬ",
    "IT - МЕНЕДЖМЕНТ",
  ];

  // if (window.innerWidth <= 400) {
  //   itFields[1] = "FRONTEND-DEV";
  //   itFields[2] = "BACKEND-DEV";
  // }

  const icons = [
    Code,
    Server,
    Globe,
    Shield,
    Cpu,
    Database,
    Layers,
    Terminal,
    CheckCircle,
  ];

  const reviews = [
    {
      name: "Амина Н.",
      role: "Бакалавр",
      text: "Спасибо большое за предоставленную возможность получить ценный опыт! Безумно благодарна Эркеновой Виктории Вячеславовне, организаторам мероприятия и своим коллегам",
      rating: 5,
    },
    {
      name: "Руслан Д.",
      role: "Магистрант",
      text: "Я очень благодарен за возможность поучаствовать в программе наставничества! Работа с Темботом Артуровичем, гендиректором Яндекс.Доставки, дала мне возможность погрузиться в мир ИТ-менеджмента и узнать о том, как функционируют ведущие ИТ-компании. Я получил ценные рекомендации от своего наставника для дальнейшего трудоустройства!",
      rating: 5,
    },
    {
      name: "Диана Р.",
      role: "Выпускница программы",
      text: "Программа наставничества в сфере IT стала для меня настоящим прорывом! Благодаря наставничеству Назира Васильевича я получила уникальные практические навыки и знания в области программирования. Участие в проектах под его руководством позволило мне пополнить портфолио и получить бесценный опыт работы в реальных условиях.",
      rating: 5,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-25 font-sans">
      <section className="relative bg-blue-700 text-white py-60 md:py-56">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="https://images.unsplash.com/photo-1555066931-4365d14bab8c?auto=format&fit=crop&w=1920&q=80"
            alt="IT-Programming background"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <AnimatedSection>
          <div className="container mx-auto px-4 text-center relative z-10">
            <h1 className="text-3xl text-blue-100 font-bold mb-6">IT</h1>
          </div>
          {icons.map((Icon, index) => (
            <IconWrapper
              key={index}
              style={{
                animationDelay: `${-index * (30 / icons.length)}s`,
                transform: `rotate(${
                  index * (360 / icons.length)
                }deg) translateX(150px) rotate(-${
                  index * (360 / icons.length)
                }deg)`,
              }}
            >
              <Icon className="text-blue-200 opacity-70 w-[40px] h-[40px]" />
            </IconWrapper>
          ))}
        </AnimatedSection>
      </section>

      <ScrollingBanner>
        <ScrollingText>
          {`${itFields.join("   •   ")}   •   `.repeat(3)}
        </ScrollingText>
      </ScrollingBanner>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto mt-8">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            КОНЦЕПЦИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <Code className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Сфера ІТ сегодня является одной из самых актуальных и
              востребованных. В современном мире, где цифровые технологии
              проникают во все сферы жизни, специалисты в области IT имеют
              огромные возможности для карьерного роста и развития.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <Server className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В рамках нашей программы наставничества вы сможете получить
              уникальные прикладные навыки и знания, работая рядом с опытными
              профессионалами этой сферы. Наставничество будет осуществляться в
              различных IT-компаниях в зависимости от специфики деятельности
              наставника.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <div className="flex justify-center items-center">
              <Globe className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            </div>
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Участие в программе наставничества в сфере IT не только позволит
              вам получить ценные знания и навыки, но и откроет двери к
              множеству возможностей в актуальной и перспективной области.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            ЭФФЕКТ ПРОГРАММЫ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Практические навыки в программировании, разработки функциональных
              сайтов.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Пополнение портфолио крупными проектами.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Стажировка и возможность трудоустройства в ведущие ИТ-компании.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Написание научных статей совместно с наставником или под его
              руководством.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Рекомендации от наставника для дальнейшего трудоустройства.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            УРОВЕНЬ ОБРАЗОВАНИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <School className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              4 курс СПО
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <University className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              3-4 курс бакалавриата
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <GraduationCap className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Магистратура
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-8 px-1 max-w-6xl mx-auto mb-4">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            НАПРАВЛЕНИЯ
          </h2>
          <AnimatedBranchTree
            mainField={"IT & CODE"}
            subFields={itFields.slice(1)}
            color={"cornflowerblue"}
          />
        </section>
      </AnimatedSection>

      <section className="py-8 lg:my-12">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-16 md:mb-24">
              НАСТАВНИКИ ПРОШЛОГО ЗАПУСКА
            </h2>
          </AnimatedSection>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-x-4 lg:gap-y-20 justify-items-center">
            {[
              {
                name: "Наурзоков Назир Васильевич",
                sphere:
                  "Руководитель направления проектных решений в Advanced Technologies",
                image: "nastav_naurzokov.webp",
              },
              {
                name: "Эркенова Виктория Вячеславовна",
                sphere:
                  'Заместитель председателя правления "Автодор" по интеллектуальным транспортным системам и цифровизации',
                image: "nastav_erkenova.webp",
              },
              {
                name: "Керефов Тембот Артурович",
                sphere: "Генеральный директор Яндекс.Доставки в РФ",
                image: "nastav_kerefov.webp",
              },
            ].map((mentor, index) => (
              <AnimatedSection key={mentor.name} className={`text-center`}>
                <img
                  src={mentor.image}
                  alt={mentor.name}
                  className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                />
                <h3 className="text-xl font-bold text-blue-800 mb-2">
                  {mentor.name}
                </h3>
                <p className="text-lg text-gray-600 mb-2 px-6">{mentor.sphere}</p>
              </AnimatedSection>
            ))}
          </div>
        </div>
      </section>

      <Reviews
        reviews={reviews}
        textSize={"base"}
        textHeight={"[12rem]"}
        titleColor={"blue"}
      />

      <JoinTelegramSection />

      <JoinProjectSection />
    </div>
  );
}
