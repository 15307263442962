import React from "react";
import styled, { keyframes } from "styled-components";

const drop = keyframes`
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
`;

const stardrop = keyframes`
  0% {
    top: calc(-50% + 15vh);
    left: calc(50% - 4px);
    transform: scale(1) rotate(360deg);
  }
  35% {
    left: calc(50% - 4px);
    transform: scale(1.5) rotate(1080deg);
  }
  100% {
    top: calc(110% + 15vh);
    left: calc(50% - 4px);
    transform: scale(2.5) rotate(1440deg);
  }
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const LinesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: 0;
`;

const Line = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(245, 245, 245, 0.1);
  overflow: visible;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -15%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(200, 255, 200, 0) 0%,
      rgb(140, 230, 140) 75%,
      rgb(20, 240, 20) 100%
    );
    animation: ${drop} 24s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.08, 0.38, 0.73, 0.79);
  }

  &:nth-child(1) {
    margin-left: -52%;
    &::after {
      animation-delay: 0.1s;
    }
  }

  &:nth-child(2) {
    margin-left: -44%;
    &::after {
      animation-delay: 11.4s;
    }
  }

  &:nth-child(3) {
    margin-left: -34%;
    &::after {
      animation-delay: 2.8s;
    }
  }

  &:nth-child(4) {
    margin-left: -24%;
    &::after {
      animation-delay: 15.3s;
    }
  }

  &:nth-child(5) {
    margin-left: -16%;
    &::after {
      animation-delay: 7.5s;
    }
  }

  &:nth-child(6) {
    margin-left: -8%;
    &::after {
      animation-delay: 18s;
    }
  }

  &:nth-child(7) {
    margin-left: 8%;
    &::after {
      animation-delay: 5.2s;
    }
  }

  &:nth-child(8) {
    margin-left: 16%;
    &::after {
      animation-delay: 12.7s;
    }
  }

  &:nth-child(9) {
    margin-left: 24%;
    &::after {
      animation-delay: 1.5s;
    }
  }

  &:nth-child(10) {
    margin-left: 34%;
    &::after {
      animation-delay: 9.1s;
    }
  }

  &:nth-child(11) {
    margin-left: 44%;
    &::after {
      animation-delay: 16.4s;
    }
  }

  &:nth-child(12) {
    margin-left: 52%;
    &::after {
      animation-delay: 5.9s;
    }
  }
`;

const Star = styled.svg`
  position: absolute;
  width: 9px;
  height: 9px;
  top: -15%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${stardrop} 24s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.08, 0.38, 0.73, 0.79);
  filter: drop-shadow(0 0 2px rgb(20, 240, 20));
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

function AnimatedBackground({ children }) {
  return (
    <AnimationContainer>
      <LinesContainer>
        {[...Array(12)].map((_, index) => (
          <Line key={index}>
            <Star
              viewBox="0 0 51 48"
              style={{
                animationDelay: `${
                  [
                    0.1, 11.4, 2.8, 15.3, 7.5, 18, 5.2, 12.7, 1.5, 9.1, 16.4,
                    5.9,
                  ][index]
                }s`,
              }}
            >
              <path
                fill="rgba(20, 240, 20, 0.75)"
                d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
              />
            </Star>
          </Line>
        ))}
      </LinesContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </AnimationContainer>
  );
}

export default AnimatedBackground;
