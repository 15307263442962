import React from "react";
import AnimatedButton from "./AnimatedButton";
import AnimatedSection from "./AnimatedSection";

const JoinProjectSection = () => {
  return (
    <section className="py-20 bg-green-700 text-white opacity-90">
      <div className="container mx-auto px-4 text-center">
        <AnimatedSection>
          <h2 className="text-3xl font-bold mb-8">Присоединяйтесь к проекту</h2>
          <p className="text-xl mb-8">
            После отправки заявки с вами свяжется наш координатор
          </p>
          <AnimatedButton
            href="/auth"
            variant="green"
            className="inline-flex items-center bg-white text-green-600 px-8 py-3 rounded-full text-lg font-semibold"
          >
            Отправить заявку
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </AnimatedButton>
        </AnimatedSection>
      </div>
    </section>
  );
};

export default JoinProjectSection;
