import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function AnimatedBranchTree({
  mainField,
  subFields,
  color = "#22c55e",
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = containerRef.current.offsetHeight;
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    const calculateHeight = () => {
      const baseHeight = 150;
      const fieldHeight = 50;
      const verticalSpacing = 10;
      const totalHeight =
        baseHeight + (fieldHeight + verticalSpacing) * subFields.length;
      setContainerHeight(Math.max(500, totalHeight));
    };

    calculateHeight();
  }, [subFields]);

  const getVerticalPosition = (index) => {
    const baseY = 180;
    const fieldHeight = 50;
    const verticalSpacing = 10;
    const x =
      dimensions.width > 500
        ? dimensions.width / 2 - 100
        : dimensions.width * 0.1;
    const y = baseY + index * (fieldHeight + verticalSpacing);
    return { x, y };
  };

  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, staggerChildren: 0.05 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  const lineVariants = {
    hidden: { pathLength: 0 },
    visible: { pathLength: 1, transition: { duration: 0.5 } },
  };

  const lastFieldY = getVerticalPosition(subFields.length - 1).y;

  return (
    <div
      ref={containerRef}
      className="relative w-full overflow-hidden flex justify-center"
      style={{ height: `${containerHeight}px` }}
    >
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variants}
        className="w-full h-full"
      >
        <motion.div
          className="absolute bg-green-500 text-white p-3 sm:p-4 md:p-5 rounded-lg shadow-lg text-center text-lg sm:text-xl md:text-2xl"
          style={{
            left: dimensions.width > 500 ? "33%" : "3%",
            transform: dimensions.width > 500 ? "translateX(-50%)" : "none",
            top: 50,
            backgroundColor: color,
            zIndex: 10,
            maxWidth: "80%",
          }}
          variants={itemVariants}
        >
          {mainField}
        </motion.div>
        <svg className="absolute top-0 left-0 w-full h-full">
          <motion.path
            d={`M ${
              dimensions.width > 500
                ? dimensions.width / 2 - 100
                : dimensions.width * 0.1
            },100 L ${
              dimensions.width > 500
                ? dimensions.width / 2 - 100
                : dimensions.width * 0.1
            },${lastFieldY}`}
            stroke={color}
            strokeWidth="2"
            fill="none"
            variants={lineVariants}
          />
          {subFields.map((_, index) => {
            const { x, y } = getVerticalPosition(index);
            return (
              <motion.path
                key={`path-${index}`}
                d={`M ${x},${y} L ${x + 20},${y}`}
                stroke={color}
                strokeWidth="2"
                fill="none"
                variants={lineVariants}
              />
            );
          })}
        </svg>
        {subFields.map((field, index) => {
          const { x, y } = getVerticalPosition(index);
          return (
            <motion.div
              key={field}
              className="absolute bg-green-600 text-white p-2 sm:p-3 rounded-lg shadow-md text-center text-xs sm:text-sm"
              style={{
                left: x + 30,
                top: y - 15,
                backgroundColor: color,
                maxWidth: "70%",
              }}
              variants={itemVariants}
            >
              {field}
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
}
