import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { uiActions } from "../../../store/ui-slice";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  styled,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";
import env from "../env";
import { useHttpClient } from "../shared/hooks/http-hook";
import { uiActions } from "../store/ui-slice";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    width: "75%",
    maxWidth: "35rem",
  },
}));

export default function OTPModal({ open, onClose, userCredentials }) {
  const dispatch = useDispatch();
  const { sendRequest } = useHttpClient();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [otpCode, setOtpCode] = useState("");
  const [otpIsVerified, setOtpIsVerified] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);

  const handleOtpChange = (event) => {
    setOtpCode(event.target.value);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    if (otpCode.length !== 6) {
      setError("Пожалуйста введите валидный 6-значный OTP-код.");
    } else {
      setIsLoading(true);

      try {
        const responseData = await sendRequest(
          env.BASE_URL + "/api/users/verify-otp",
          "POST",
          {
            "Content-Type": "application/json",
          },
          JSON.stringify({
            email: userCredentials.email,
            otp: otpCode,
          })
        );

        if (responseData.success) {
          setSuccessMessage(
            "OTP-код верифицирован, нажмите ''Создать аккаунт'' ниже"
          );
          setOtpIsVerified(true);
          setError("");
        } else {
          setError(responseData.message);
        }
      } catch (error) {
        setError("Возникла ошибка при верификации OTP-кода");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const createUser = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const responseData = await sendRequest(
        env.BASE_URL + "/api/users/signup",
        "POST",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          name: userCredentials.name,
          surname: userCredentials.surname,
          sphere: userCredentials.sphere,
          email: userCredentials.email,
          phoneNumber: userCredentials.phoneNumber,
          password: userCredentials.password,
        })
      );

      if (responseData.success) {
        setSuccessMessage(
          "Пользовательский аккаунт зарегистрирован, нажмите ''Войти'' ниже"
        );
        setAccountCreated(true);

        dispatch(
          uiActions.login({
            user: {
              id: responseData.userId,
              name: responseData.name,
              surname: responseData.surname,
              sphere: responseData.sphere,
              email: responseData.email,
              phoneNumber: responseData.phoneNumber,
              token: responseData.token,
            },
          })
        );
      } else {
        setError(responseData.message);
      }
    } catch (error) {
      setError("Возникла ошибка при регистрации пользовательского аккаунта");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Подтверждение почты
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          OTP-код (6 цифр) выслан на Вашу почту, введите его ниже
        </Typography>
        {error && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        {successMessage && (
          <Typography color="success" variant="body2" sx={{ mb: 2 }}>
            {successMessage}
          </Typography>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="otp"
          label="Введите OTP-код"
          type="text"
          fullWidth
          variant="outlined"
          value={otpCode}
          onChange={handleOtpChange}
          inputProps={{ maxLength: 6 }}
        />
        {isLoading && <ButtonLoadingSpinner />}
        {!isLoading && (
          <>
            {!otpIsVerified && (
              <Button
                onClick={handleVerifyOtp}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Верифицировать код
              </Button>
            )}
            {otpIsVerified && !accountCreated && (
              <Button
                onClick={createUser}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Создать аккаунт
              </Button>
            )}
            {accountCreated && (
              <Button
                onClick={() => {
                  setSuccessMessage("Вход успешен, перенаправляем...");
                  setTimeout(() => {
                    window.location.assign("/");
                  }, 1000);
                }}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Войти
              </Button>
            )}
          </>
        )}
      </DialogContent>
    </StyledDialog>
  );
}
