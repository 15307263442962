import React from "react";
import { styled, keyframes } from "styled-components";
import {
  BarChart2,
  DollarSign,
  TrendingUp,
  Briefcase,
  CheckCircle,
  Building,
  PieChart,
  FileText,
  Users,
  School,
  University,
  GraduationCap,
} from "lucide-react";
import AnimatedBackground from "../components/AnimatedBackground";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import JoinProjectSection from "../components/JoinProjectSection";
import AnimatedBranchTree from "../components/AnimatedBranchTree";
import Reviews from "../components/Reviews";

const rotate = keyframes`
  from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
  to { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
`;

const scroll = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;

const ScrollingBanner = styled.div`
  background-color: #047857;
  color: white;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} 30s linear infinite;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -20px;
  margin-left: -20px;
  animation: ${rotate} 30s linear infinite;
  color: white;
`;

export default function Economics() {
  const economicsFields = [
    "ЭКОНОМИКА",
    "ТОРГОВОЕ ДЕЛО",
    "ФИНАНСЫ И УЧЕТ",
    "ЭКОНОМИЧЕСКАЯ БЕЗОПАСНОСТЬ",
    "БАНКОВСКОЕ ДЕЛО",
    "ТАМОЖЕННОЕ ДЕЛО",
    "АУДИТ",
    "СЕЛЬСКОЕ ХОЗЯЙСТВО",
    "ТУРИЗМ",
    "ГОСТИНИЧНОЕ ДЕЛО",

    "БИЗНЕС",
    "УПРАВЛЕНИЕ БИЗНЕСОМ",
    "МАРКЕТИНГ И РЫНОЧНАЯ АНАЛИТИКА",
    "МЕЖДУНАРОДНЫЙ БИЗНЕС",
    "БИЗНЕС - ИНФОРМАТИКА",
    "БИЗНЕС - АНАЛИТИКА",
    "МЕНЕДЖМЕНТ",
  ];

  // if (window.innerWidth <= 400) {
  //   economicsFields[1] = "ФИНАНС. РЫНКИ";
  //   economicsFields[3] = "ФИН. В ГОСОРГАНАХ";
  // }

  const icons = [
    BarChart2,
    DollarSign,
    TrendingUp,
    Briefcase,
    Building,
    PieChart,
    FileText,
    Users,
    CheckCircle,
  ];

  const reviews = [
    {
      name: "Диана Д.",
      role: "Выпускница программы",
      text: "Опыт Анзора Казбековича в бизнесе и на государственной службе оказался для меня невероятно полезным. Благодаря его наставничеству я узнала много ценной информации о том, как вести себя во время бизнес-переговоров, как эффективно выступать перед большой аудиторией. Анзор Казбекович поделился секретами позиционирования бизнеса на мероприятиях по развитию туризма и как правильно предлагать услуги, чтобы привлечь больше клиентов.",
      rating: 5,
    },
    {
      name: "Заур Т.",
      role: "Магистр",
      text: 'Я был впечатлен тем, как Анзор Казбекович умело реализует свои проекты, такие как "Кавказ Grand Turismo" и "Золотое кольцо Боспорского царства". Его опыт и знания в области туризма и предпринимательства позволили мне лучше понять нюансы этих сфер. Наставничество Анзора Казбековича значительно расширило мои горизонты и помогло мне по-новому взглянуть на многие вещи.',
      rating: 5,
    },
    {
      name: "Алихан М.",
      role: "Выпускник программы",
      text: "Рад, что выбрал именно Анзора Казбековича своим наставником. Проект наставничества дал мне возможность не только получить новые знания, но и познакомиться с интересными людьми. Анзор Казбекович - настоящий профессионал, и я очень ценю его советы и поддержку. Благодаря его уникальному опыту и помощи я вновь почувствовал себя более уверенным и готов к новым вызовам в бизнесе.",
      rating: 5,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-25 font-sans">
      <section className="relative bg-emerald-700 text-white py-60 md:py-56">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1920&q=80"
            alt="Economics and Business background"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <AnimatedSection>
          <div className="container mx-auto px-4 text-center relative z-10">
            <h1 className="text-xl text-emerald-100 font-bold mb-6">
              ЭКОНОМИКА И БИЗНЕС
            </h1>
          </div>
          {icons.map((Icon, index) => (
            <IconWrapper
              key={index}
              style={{
                animationDelay: `${-index * (30 / icons.length)}s`,
                transform: `rotate(${
                  index * (360 / icons.length)
                }deg) translateX(150px) rotate(-${
                  index * (360 / icons.length)
                }deg)`,
              }}
            >
              <Icon className="text-emerald-200 opacity-70 w-[40px] h-[40px]" />
            </IconWrapper>
          ))}
        </AnimatedSection>
      </section>

      <ScrollingBanner>
        <ScrollingText>
          {`${economicsFields.join("   •   ")}   •   `.repeat(3)}
        </ScrollingText>
      </ScrollingBanner>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto mt-8">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-emerald-800 mb-8 md:mb-16">
            КОНЦЕПЦИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <BarChart2 className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              B рамках сферы "Экономика и бизнес" наставляемые будут иметь
              возможность получить уникальные прикладные навыки и знания
              посредством коммуникации со специалистами в области банковского
              сектора, финансов и аудита, топ-менеджерами, предпринимателями и
              лицами, имеющими успешный опыт в запуске и продвижении
              собственного бизнеса.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <Briefcase className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Наставничество будет осуществляться в форме личного взаимодействия
              наставников с наставляемыми, в ходе которого наставник будет
              делиться с наставляемым своим профессиональным опытом, путем
              разбора реальных экономических и бизнес-ситуаций.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <div className="flex justify-center items-center">
              <TrendingUp className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            </div>
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В ходе сессии проекта наставляемые будут иметь возможность
              закрепить и углубить теоретические знания, изучить специфику
              деятельности представленных направлений экономики, приобрести
              практические навыки работы в различных отраслях сферы, выстроить
              понимание своего профессионального развития и обрести
              профессиональные коммуникационные сети для дальнейшего
              сотрудничества, выходящего за пределы проекта.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-emerald-800 mb-8 md:mb-16">
            ЭФФЕКТ ПРОГРАММЫ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Практические навыки, развитие деловой этики, корпоративной
              культуры, коммуникативных навыков.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Пополнение портфолио крупными проектами.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Стажировка и возможность трудоустройства.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Написание научных работ совместно с наставником, либо под его
              руководством.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Рекомендации от наставника для дальнейшего трудоустройства.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-emerald-800 mb-8 md:mb-16">
            УРОВЕНЬ ОБРАЗОВАНИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <School className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              4 курс СПО
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <University className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              3-4 курс бакалавриата
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <GraduationCap className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-emerald-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Магистратура
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-8 px-1 max-w-6xl mx-auto mb-4">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-emerald-800 mb-8 md:mb-16">
            НАПРАВЛЕНИЯ
          </h2>
          <AnimatedBranchTree
            mainField={"Экономика"}
            subFields={economicsFields.slice(1, 10)}
            color={"darkcyan"}
          />
          <div className="my-8"></div>
          <AnimatedBranchTree
            mainField={"Бизнес"}
            subFields={economicsFields.slice(11)}
            color={"darkcyan"}
          />
        </section>
      </AnimatedSection>

      <section className="py-8 lg:my-12">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-emerald-800 mb-16 md:mb-24">
              НАСТАВНИКИ ПРОШЛОГО ЗАПУСКА
            </h2>
          </AnimatedSection>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-x-4 lg:gap-y-20 justify-items-center">
            {[
              {
                name: "Тимижев Хасан Хамишевич",
                sphere: 'Исполнительный директор АО "Кавказ РФ"',
                image: "nastav_timizhev.webp",
              },
              {
                name: "Макоев Тали Ахмедович",
                sphere:
                  'Директор департамента военно-технического сотрудничества АО "Росэлектроника"',
                image: "nastav_makoev.webp",
              },
              {
                name: "Емтыль Анзор Казбекович",
                sphere: 'Исполнительный директор компании "Турлизинг.РФ"',
                image: "nastav_emtil.webp",
              },
            ].map((mentor, index) => (
              <AnimatedSection key={mentor.name} className={`text-center`}>
                <img
                  src={mentor.image}
                  alt={mentor.name}
                  className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                />
                <h3 className="text-xl font-bold text-emerald-800 mb-2">
                  {mentor.name}
                </h3>
                <p className="text-lg text-gray-600 mb-2 px-6">
                  {mentor.sphere}
                </p>
              </AnimatedSection>
            ))}
          </div>
        </div>
      </section>

      <Reviews
        reviews={reviews}
        textSize={"base"}
        textHeight={"auto"}
        titleColor={"emerald"}
      />

      <JoinTelegramSection />

      <JoinProjectSection />
    </div>
  );
}
