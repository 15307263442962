import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-green-800 text-white py-6 md:py-8">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="sm:flex sm:gap-x-1">
          <p>&copy; 2024 Проект "Шаг в Будущее".</p>
          <p>Все права защищены.</p>
        </div>
        <div className="mt-4 md:mt-0 grid grid-cols-2 sm:flex sm:gap-x-4">
          <a href={"/about"} className="hover:underline md:mr-4">
            О проекте
          </a>
          <a href={"/contact"} className="hover:underline md:mr-4">
            Контакты
          </a>
          <a href={"/legal"} className="hover:underline col-span-2">
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
