import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Medicine from "./pages/Medicine";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Jurisprudence from "./pages/Jurisprudence";
import Economics from "./pages/Economics";
import PRSMM from "./pages/PRSMM";
import ITProgramming from "./pages/ITProgramming";
import Auth from "./pages/Auth";
import PersonalAccount from "./pages/PersonalAccount";
import LoadingSpinner from "./shared/LoadingSpinner";
import ErrorModal from "./shared/ErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { uiActions } from "./store/ui-slice";
import env from "./env";
import Admin from "./pages/Admin";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Legal from "./pages/Legal";

function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.ui.isLoading);
  const error = useSelector((state) => state.ui.error);
  const user = useSelector((state) => state.ui.user);
  const isLogged = useSelector((state) => state.ui.isLogged);
  const isAdmin = user && user.email && env.ADMINS_LIST.includes(user.email);

  useEffect(() => {
    console.log("WEBSITE RELOADED");
  }, []);

  const clearError = () => {
    dispatch(uiActions.setError(null));
  };

  const location = useLocation();

  return (
    <div className="App">
      {isLoading && <LoadingSpinner asOverlay />}
      {<ErrorModal error={error} onClear={clearError} />}

      <Header />

      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/medicine" element={<Medicine />} />
        <Route exact path="/jurisprudence" element={<Jurisprudence />} />
        <Route exact path="/economics" element={<Economics />} />
        <Route exact path="/pr-smm" element={<PRSMM />} />
        <Route exact path="/it-programming" element={<ITProgramming />} />

        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/legal" element={<Legal />} />

        <Route
          exact
          path="/auth"
          element={isLogged ? <PersonalAccount /> : <Auth />}
        />
        <Route exact path="/account" element={<PersonalAccount />} />

        <Route
          exact
          path="/admin"
          element={
            isAdmin ? (
              <Admin />
            ) : (
              <Navigate to="/" replace state={{ from: location }} />
            )
          }
        />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
