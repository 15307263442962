import React from "react";
import { styled, keyframes } from "styled-components";
import {
  Share2,
  TrendingUp,
  BarChart2,
  Users,
  CheckCircle,
  MessageCircle,
  PieChart,
  Target,
  Megaphone,
  School,
  University,
  GraduationCap,
} from "lucide-react";
import AnimatedBackground from "../components/AnimatedBackground";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import JoinProjectSection from "../components/JoinProjectSection";
import AnimatedBranchTree from "../components/AnimatedBranchTree";
import Reviews from "../components/Reviews";

const rotate = keyframes`
  from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
  to { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
`;

const scroll = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;

const ScrollingBanner = styled.div`
  background-color: #7c3aed;
  color: white;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} 30s linear infinite;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -20px;
  margin-left: -20px;
  animation: ${rotate} 30s linear infinite;
  color: white;
`;

export default function PRSMM() {
  const marketingFields = [
    "PR & SMM",
    "РЕКЛАМА И СВЯЗИ С ОБЩЕСТВЕННОСТЬЮ",
    // "РЕКЛАМА И СВЯЗИ С ОБЩЕСТВЕННОСТЬЮ В ЦИФРОВЫХ МЕДИА",
    "МАРКЕТИНГ И УПРАВЛЕНИЕ БРЕНДАМИ",
    "СОЗДАНИЕ И ПРОДВИЖЕНИЕ МЕДИАПРОДУКТОВ",
    "БРЕНД-МЕНЕДЖМЕНТ В МЕДИАПРОСТРАНСТВЕ",
    "ЦИФРОВЫЕ И МАРКЕТИНГОВЫЕ КОММУНИКАЦИИ",
  ];

  const icons = [
    Share2,
    TrendingUp,
    BarChart2,
    Users,
    MessageCircle,
    PieChart,
    Target,
    Megaphone,
    CheckCircle,
  ];

  const reviews = [
    {
      name: "Элина Н.",
      role: "Студентка старших курсов",
      text: "Общение с наставником проходит очень комфортно, разница во времени не ощущается. Ислам всегда находится на связи и отвечает, как только появляется возможность.",
      rating: 5,
    },
    {
      name: "Заур Д.",
      role: "Магистрант",
      text: "Участие в проекте позволило мне пополнить портфолио реальными проектами, что может не радовать! Я получил от наставников ценные рекомендации и советы, которые помогли мне выстроить понимание моего дальнейшего профессионального развития.",
      rating: 5,
    },
    {
      name: "Рамиль В.",
      role: "Выпускник программы",
      text: "Очень благодарен данной профессиональной программе! Получил практический опыт работы в сфере маркетинга и рекламы, что значительно повысило мою конкурентоспособность на рынке труда.",
      rating: 5,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-25 font-sans">
      <section className="relative bg-violet-700 text-white py-60 md:py-56">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="/prsmm.webp"
            alt="PR & SMM background"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <AnimatedSection>
          <div className="container mx-auto px-4 text-center relative z-10">
            <h1 className="text-3xl text-violet-100 font-bold mb-6">
              PR & SMM
            </h1>
          </div>
          {icons.map((Icon, index) => (
            <IconWrapper
              key={index}
              style={{
                animationDelay: `${-index * (30 / icons.length)}s`,
                transform: `rotate(${
                  index * (360 / icons.length)
                }deg) translateX(150px) rotate(-${
                  index * (360 / icons.length)
                }deg)`,
              }}
            >
              <Icon className="text-violet-200 opacity-70 w-[40px] h-[40px]" />
            </IconWrapper>
          ))}
        </AnimatedSection>
      </section>

      <ScrollingBanner>
        <ScrollingText>
          {`${marketingFields.join("   •   ")}   •   `.repeat(3)}
        </ScrollingText>
      </ScrollingBanner>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto mt-8">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-violet-800 mb-8 md:mb-16">
            КОНЦЕПЦИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <Share2 className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В рамках сферы «PR&SMM » наставляемые будут иметь возможность
              получить уникальные прикладные навыки и знания во время
              взаимодействия с лицами, работающими в сфере маркетинга и рекламы,
              которые обладают большим профессиональным опытом в данной сфере.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <Users className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Наставничество будет осуществляться в компаниях и организациях в
              зависимости от специфики деятельности наставника.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <div className="flex justify-center items-center">
              <TrendingUp className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            </div>
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В рамках программы, наставляемые будут иметь возможность изучить
              специфику деятельности разных направлений маркетинга, углубить
              свои знания в реализации проектов, получить практические навыки
              работы в отделе маркетинга и рекламы, в создании SMM-продуктов,
              выстроить понимание дальнейшего профессионального развития.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-violet-800 mb-8 md:mb-16">
            ЭФФЕКТ ПРОГРАММЫ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Практические навыки по работе с Excel, CRM - системами, по
              разработке SMM продукта и его продвижению.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Пополнение портфолио крупными проектами, SMM продуктами.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Стажировка и возможность трудоустройства.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Рекомендации от наставника для дальнейшего трудоустройства.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-violet-800 mb-8 md:mb-16">
            УРОВЕНЬ ОБРАЗОВАНИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <School className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              4 курс СПО
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <University className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              3-4 курс бакалавриата
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <GraduationCap className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-violet-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Магистратура
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-8 px-1 max-w-6xl mx-auto mb-4">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-violet-800 mb-8 md:mb-16">
            НАПРАВЛЕНИЯ
          </h2>
          <AnimatedBranchTree
            mainField={marketingFields[0]}
            subFields={marketingFields.slice(1)}
            color={"purple"}
          />
        </section>
      </AnimatedSection>

      <section className="py-8 lg:my-12">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-violet-800 mb-16 md:mb-24">
              НАСТАВНИКИ ПРОШЛОГО ЗАПУСКА
            </h2>
          </AnimatedSection>
          <div className="grid grid-cols-1 gap-8 lg:gap-x-4 lg:gap-y-20 justify-items-center">
            {[
              {
                name: "Мукожев Ислам Алиханович",
                sphere: "Global product Marketing Lead, YouTube Premium",
                image: "nastav_mukozhev.webp",
              },
            ].map((mentor, index) => (
              <AnimatedSection key={mentor.name} className={`text-center`}>
                <img
                  src={mentor.image}
                  alt={mentor.name}
                  className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                />
                <h3 className="text-xl font-bold text-violet-800 mb-2">
                  {mentor.name}
                </h3>
                <p className="text-lg text-gray-600 mb-2">{mentor.sphere}</p>
              </AnimatedSection>
            ))}
          </div>
        </div>
      </section>

      <Reviews reviews={reviews} titleColor={"violet"} />

      <JoinTelegramSection />

      <JoinProjectSection />
    </div>
  );
}
