import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import AnimatedButton from "../components/AnimatedButton";
import AnimatedBackground from "../components/AnimatedBackground";
import styled from "styled-components";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import JoinProjectSection from "../components/JoinProjectSection";
import { ChevronLeft, ChevronRight } from "lucide-react";
import env from "../env";

export default function Home() {
  const [currentImage, setCurrentImage] = useState(0);
  const windowWidth = window.innerWidth;

  const images = [
    windowWidth > 500 ? "slide1.webp" : "slide1_mobile.webp",
    windowWidth > 500 ? "slide2.webp" : "slide2_mobile.webp",
    windowWidth > 500 ? "slide3.webp" : "slide3_mobile.webp",
    // windowWidth > 500 ? "slide4.webp" : "slide4_mobile.webp",
    "slide5.webp",
    "slide6.webp",
    "slide7.webp",
    "slide8.webp",
    "slide9.webp",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const goToPrevious = () => {
    setCurrentImage(
      (prevImage) => (prevImage - 1 + images.length) % images.length
    );
  };

  const goToNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-25">
      <main className="flex-grow">
        <section className="relative h-[24rem] md:h-[26rem] lg:h-[30rem] xl:h-[31rem] 2xl:h-[46rem]">
          <div className="absolute inset-0 overflow-hidden">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index + 1}`}
                className={`absolute inset-0 w-full h-full object-cover transition-all duration-1000 ease-in-out ${
                  index === currentImage
                    ? "opacity-100 transform scale-100"
                    : "opacity-0 transform scale-110"
                }`}
              />
            ))}
            <div className="absolute inset-0 bg-black opacity-35"></div>
          </div>

          <div className="absolute inset-x-0 bottom-12 z-10">
            <div className="container mx-auto px-4 text-center">
              <AnimatedButton
                href="/auth"
                variant="green"
                backgroundOpacity={0.6}
                width={"21rem"}
                fontSize={"16px"}
                // height={"3.5rem"}
                className="inline-flex items-center bg-white text-green-600 px-8 py-3 rounded-full text-lg font-semibold mb-8"
              >
                Присоединяйся к новому набору
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </AnimatedButton>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-4 z-20">
            <div className="flex justify-center space-x-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    index === currentImage
                      ? "bg-white scale-125"
                      : "bg-gray-500 border-2 border-gray-400"
                  }`}
                  onClick={() => setCurrentImage(index)}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>

          <button
            onClick={goToPrevious}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-500 bg-opacity-50 p-2 rounded-full text-white hover:bg-opacity-75 transition-all duration-200 z-20"
            aria-label="Previous image"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>

          <button
            onClick={goToNext}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-500 bg-opacity-50 p-2 rounded-full text-white hover:bg-opacity-75 transition-all duration-200 z-20"
            aria-label="Next image"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </section>

        {/* <section className="relative bg-green-700 text-white py-32">
          <div className="absolute inset-0">
            <video
              autoPlay
              loop
              muted
              playsInline
              controls={""}
              className="object-cover w-full h-full"
              src="/headvideo.mp4"
            />
            <div className="absolute inset-0 bg-black opacity-60"></div>
          </div>
          <AnimatedSection>
            <div className="container mx-auto px-4 text-center relative z-10">
              <h1 className="text-3xl md:text-5xl text-green-50 font-bold mb-6">
                Добро пожаловать в проект Наставничества
              </h1>
              <p className="text-xl text-green-50 mb-8">
                Раскройте свой потенциал с помощью опытных экспертов
              </p>
              <AnimatedButton
                href="/auth"
                variant="green"
                className="inline-flex items-center bg-white text-green-600 px-8 py-3 rounded-full text-lg font-semibold"
              >
                Присоединиться
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </AnimatedButton>
            </div>
          </AnimatedSection>
        </section> */}

        <AnimatedBackground>
          <section className="py-8 md:py-16">
            <div className="container mx-auto px-4">
              <div className="flex flex-col md:flex-row justify-between items-stretch">
                <AnimatedSection
                  direction="left"
                  className="md:w-1/2 mb-12 md:mb-8 md:mb-0 md:pr-8"
                >
                  <h2 className="text-2xl font-bold text-green-800 mb-4">
                    "ШАГ В БУДУЩЕЕ"
                  </h2>
                  <p className="text-gray-600 leading-relaxed">
                    Суть проекта заключается в организации площадки
                    взаимодействия между инициативными студентами и
                    профессионалами из разных сфер, включая Медицину,
                    Юриспруденцию, Экономику и Бизнес, PR&SMM, IT.
                  </p>
                </AnimatedSection>
                <AnimatedSection
                  direction="right"
                  className="md:w-1/2 md:pl-8 md:border-l border-green-300"
                >
                  <h2 className="text-2xl font-bold text-green-800 mb-4">
                    ЦЕЛЬ ПРОЕКТА
                  </h2>
                  <p className="text-gray-600 leading-relaxed">
                    Личностная и профессиональная самореализация студенческой
                    молодежи в различных сферах деятельности посредством
                    организации эффективного взаимодействия наставнических пар.
                  </p>
                </AnimatedSection>
              </div>
            </div>
          </section>

          <section className="py-8">
            <div className="container mx-auto px-4">
              <AnimatedSection>
                <h2 className="text-3xl md:text-4xl font-bold text-center text-green-800 mb-8 md:mb-16">
                  СФЕРЫ ДЕЯТЕЛЬНОСТИ
                </h2>
              </AnimatedSection>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {[
                  {
                    name: "Медицина",
                    link: "/medicine",
                    direction: "left",
                    image: "sphere_med.webp",
                    // image:
                    //   "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?w=500&h=500&fit=crop",
                  },
                  {
                    name: "Юриспруденция",
                    link: "/jurisprudence",
                    direction: "right",
                    image: "sphere_jur.webp",
                    // image: "/jurisprudence.webp",
                  },
                  {
                    name: "Экономика и Бизнес",
                    link: "/economics",
                    direction: "left",
                    image: "sphere_eco.webp",
                    // image:
                    //   "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=500&h=500&fit=crop",
                  },
                  {
                    name: "PR & SMM",
                    link: "/pr-smm",
                    direction: "right",
                    image: "sphere_pr.webp",
                    // image:
                    //   "https://images.unsplash.com/photo-1562577309-4932fdd64cd1?w=500&h=500&fit=crop",
                  },
                  {
                    name: "IT",
                    link: "/it-programming",
                    direction: "up",
                    image: "sphere_it.webp",
                    // image:
                    //   "https://images.unsplash.com/photo-1498050108023-c5249f4df085",
                  },
                ].map((topic, index) => (
                  <AnimatedSection
                    key={topic.name}
                    direction={topic.direction}
                    className={`relative overflow-hidden shadow-lg group h-80 hover:cursor-pointer md:last:col-span-2
                    ${
                      index % 2 === 0
                        ? "rounded-tl-3xl rounded-br-3xl"
                        : "rounded-tr-3xl rounded-bl-3xl"
                    }`}
                    onClick={() => {
                      window.location.assign(topic.link);
                    }}
                  >
                    <img
                      src={topic.image}
                      alt={topic.name}
                      className="w-full h-full object-cover transition duration-300 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                    <h3 className="absolute bottom-4 left-4 text-white text-4xl font-semibold text-left">
                      {topic.name}
                    </h3>
                  </AnimatedSection>
                ))}
              </div>
            </div>
          </section>

          <section className="py-8 lg:my-12">
            <div className="container mx-auto px-2">
              <AnimatedSection>
                <h2 className="text-3xl md:text-4xl font-bold text-center text-green-800 mb-16 md:mb-24">
                  РУКОВОДИТЕЛИ НАПРАВЛЕНИЙ
                </h2>
              </AnimatedSection>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 md:gap-6 lg:gap-x-4 lg:gap-y-20 justify-items-center">
                {[
                  {
                    name: "Ходжаева Лолита",
                    sphere: '"Медицина"',
                    image: "nastav_med.webp",
                  },
                  {
                    name: "Утова Карина",
                    sphere: '"Юриспруденция"',
                    image: "nastav_jur.webp",
                  },
                  {
                    name: "Абитов Айдар",
                    sphere: '"Экономика и Бизнес"',
                    image: "nastav_eco.webp",
                  },
                  {
                    name: "Ахаминова Алина",
                    sphere: '"PR & SMM"',
                    image: "nastav_pr.webp",
                  },
                  {
                    name: "Хатков Амир",
                    sphere: '"IT"',
                    image: "nastav_it.webp",
                  },
                ].map((mentor, index) => (
                  <AnimatedSection
                    key={mentor.name}
                    className={`text-center ${
                      index === 3
                        ? "lg:col-start-2 lg:col-span-2"
                        : "lg:col-span-2"
                    } ${index === 4 ? "col-span-2" : ""}`}
                  >
                    <img
                      src={mentor.image}
                      alt={mentor.name}
                      className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                    />
                    <h3 className="text-xl font-bold text-green-800 mb-2">
                      {mentor.name}
                    </h3>
                    <p className="text-lg text-gray-600">
                      Руководитель направления
                    </p>
                    <p className="text-lg text-gray-600 mb-2">
                      {mentor.sphere}
                    </p>
                  </AnimatedSection>
                ))}
              </div>
            </div>
          </section>

          <section className="py-8 lg:my-8">
            <div className="container mx-auto px-4 text-center">
              <AnimatedSection>
                <h2 className="text-3xl md:text-4xl font-bold text-center text-green-800 mb-16 md:mb-24">
                  РУКОВОДИТЕЛЬ ПРОЕКТА
                </h2>
              </AnimatedSection>
              <AnimatedSection>
                <img
                  src="rukovoditel.webp"
                  alt="Руководитель проекта"
                  className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                />
                <h3 className="text-2xl font-bold text-green-800 mb-2">
                  Бельбекова Зарема
                </h3>
                {/* <p className="text-xl text-gray-600 mb-8">
                  Директор по развитию
                </p> */}
              </AnimatedSection>
            </div>
          </section>

          <section className="py-8">
            <div className="container mx-auto px-4">
              <AnimatedSection>
                <h2 className="text-3xl md:text-4xl font-bold text-center text-green-800 mb-8 md:mb-16">
                  СВЯЖИТЕСЬ С НАМИ
                </h2>
              </AnimatedSection>
              <div className="flex flex-col md:flex-row items-center justify-center">
                <AnimatedSection direction="left">
                  <img
                    src="/molpred.webp"
                    alt="Представительство"
                    className="mb-8 md:mb-0 md:mr-16 w-full w-48 h-48 md:w-72 md:h-72 object-contain"
                  />
                </AnimatedSection>
                <AnimatedSection direction="right">
                  <div>
                    <h3 className="text-2xl font-bold text-green-700 mb-2">
                      Молодёжное Представительство
                    </h3>
                    <h3 className="text-md font-bold text-green-700 opacity-80">
                      Республики Адыгея,
                    </h3>
                    <h3 className="text-md font-bold text-green-700 opacity-80">
                      Кабардино - Балкарской Республики
                    </h3>
                    <h3 className="text-md font-bold text-green-700 opacity-80">
                      Карачаево - Черкесской Республики
                    </h3>
                    <h3 className="text-md font-bold text-green-700 mb-2 opacity-80">
                      и Краснодарского края
                    </h3>
                    <h4 className="text-xs font-normal text-green-800 mb-4 opacity-70">
                      В МОСКВЕ
                    </h4>
                    <p className="text-xl text-green-900 mb-4">
                      Email: Nastavnichestvo01@mail.ru
                    </p>
                  </div>
                </AnimatedSection>
              </div>
            </div>
          </section>

          <section>
            <img
              src="/mountains.webp"
              alt="mountain range"
              className="w-full"
            />
          </section>
        </AnimatedBackground>

        <JoinTelegramSection />

        <JoinProjectSection />
      </main>
    </div>
  );
}
