import React from "react";
import AnimatedBackground from "../components/AnimatedBackground";

export default function Contact() {
  return (
    <AnimatedBackground>
      <div className="min-h-screen bg-gray-25 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Контактная информация
          </h1>

          <div className="bg-gray-50 shadow-md rounded-lg p-6 mb-8 text-left text-justify">
            <p className="mb-2">
              <strong>Email:</strong> Nastavnichestvo01@mail.ru
            </p>
            <p>
              <strong>Телеграм:</strong> @nastavnichestvo24
            </p>
          </div>
        </div>
      </div>
    </AnimatedBackground>
  );
}
