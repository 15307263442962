import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "80%",
    backgroundColor: "#f5f5f5",
    maxWidth: "40rem",
    marginBottom: "20vh",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  padding: theme.spacing(2, 1),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 1),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 1),
}));

const Modal = ({ onCancel, header, show, footer, children }) => {
  return (
    <StyledDialog
      open={show}
      onClose={onCancel}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <StyledDialogTitle id="error-dialog-title">{header}</StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
      <StyledDialogActions>{footer}</StyledDialogActions>
    </StyledDialog>
  );
};

const ErrorModal = ({ error, onClear }) => {
  return (
    <Modal
      onCancel={onClear}
      header="Error occurred!"
      show={!!error}
      footer={
        <Button onClick={onClear} color="error" variant="contained">
          OK
        </Button>
      }
    >
      <p className="text-xl m-4">{error}</p>
    </Modal>
  );
};

export default ErrorModal;
