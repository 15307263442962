import { createSlice } from "@reduxjs/toolkit";
import env from "../env";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    readyToRender: false,
    isLoading: false,
    error: null,
    isLogged: undefined,
    users: [],
    user: {},
  },
  reducers: {
    setReadyToRender(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload.users;
    },
    login(state, action) {
      const userData = action.payload.user;

      console.log("userData", userData);

      state.user.id = userData.id;
      state.user.name = userData.name;
      state.user.surname = userData.surname;
      state.user.sphere = userData.sphere;
      state.user.email = userData.email;
      state.user.phoneNumber = userData.phoneNumber;

      state.user.formStageStatus = userData.formStageStatus;
      state.user.formStageApproved = userData.formStageApproved;

      state.user.testStageStatus = userData.testStageStatus;
      state.user.testStageApproved = userData.testStageApproved;

      state.user.interviewStageStatus = userData.interviewStageStatus;
      state.user.interviewStageDate = userData.interviewStageDate;
      state.user.interviewStageApproved = userData.interviewStageApproved;

      state.user.role = userData.role;
      state.user.token = userData.token;
      state.isLogged = true;

      const tokenExpirationDate =
        userData.tokenExpirationDate ||
        new Date(new Date().getTime() + 1000 * 60 * 60 * 72); // 1000 * 60 * 60 * 72 = 72 hours || 1000 * 5 = 5 seconds
      state.user.tokenExpirationDate = tokenExpirationDate.toISOString();

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: userData.id,
          name: userData.name,
          surname: userData.surname,
          sphere: userData.sphere,
          email: userData.email,
          phoneNumber: userData.phoneNumber,

          formStageStatus: userData.formStageStatus,
          formStageApproved: userData.formStageApproved,

          testStageStatus: userData.testStageStatus,
          testStageApproved: userData.testStageApproved,

          interviewStageStatus: userData.interviewStageStatus,
          interviewStageDate: userData.interviewStageDate,
          interviewStageApproved: userData.interviewStageApproved,

          role: userData.role,
          token: userData.token,
          tokenExpirationDate: tokenExpirationDate.toISOString(),
        })
      );
    },
    logout(state) {
      state.user.id = null;
      state.name = null;
      state.surname = null;
      state.sphere = null;
      state.email = null;
      state.phoneNumber = null;

      state.formStageStatus = null;
      state.formStageApproved = null;

      state.testStageStatus = null;
      state.testStageApproved = null;

      state.interviewStageStatus = null;
      state.interviewStageDate = null;
      state.interviewStageApproved = null;

      state.role = null;
      state.user.token = null;
      state.user.tokenExpirationDate = null;
      state.user = {};
      state.isLogged = false;
      localStorage.removeItem("userData");
    },
  },
});

export const loadUserById = (sendRequest, userId) => {
  return async (dispatch) => {
    try {
      const responseData = await sendRequest(
        env.BASE_URL + `/api/users/${userId}`,
        "GET",
        {
          "Content-Type": "application/json",
        }
      );

      console.log("loadUserById", responseData);

      dispatch(uiActions.login(responseData));
    } catch (error) {}
  };
};

export const loadUsers = (sendRequest) => {
  return async (dispatch) => {
    try {
      const responseData = await sendRequest(
        env.BASE_URL + "/api/users",
        "GET",
        {
          "Content-Type": "application/json",
        }
      );

      dispatch(uiActions.setUsers(responseData));
    } catch (error) {}
  };
};

export const uiActions = uiSlice.actions;

export default uiSlice;
