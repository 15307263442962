import React, { useRef } from "react";
import { styled, keyframes } from "styled-components";
import {
  Heart,
  Brain,
  Syringe,
  Stethoscope,
  CheckCircle,
  BriefcaseMedical,
  Pill,
  PillBottle,
  Microscope,
  SquareActivity,
  GraduationCap,
  Book,
  LibraryBig,
  School,
  University,
} from "lucide-react";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import JoinProjectSection from "../components/JoinProjectSection";
import AnimatedBranchTree from "../components/AnimatedBranchTree";
import Reviews from "../components/Reviews";

const rotate = keyframes`
  from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
  to { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
`;

const scroll = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;

const ScrollingBanner = styled.div`
  background-color: #00a86b;
  color: white;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} 30s linear infinite;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -20px;
  margin-left: -20px;
  animation: ${rotate} 30s linear infinite;
  color: white;
`;

export default function Medicine() {
  const medicalFields = [
    "МЕДИЦИНА",
    "ЭНДОКРИНОЛОГИЯ",
    "НЕЙРОХИРУРГИЯ",
    "КАРДИОЛОГИЯ",
    "ОНКОЛОГИЯ",
    "ОФТАЛЬМОЛОГИЯ",
    "АКУШЕРСТВО И ГИНЕКОЛОГИЯ",
    "ДЕРМАТОВЕНЕРОЛОГИЯ",
    "КОЛОПРОКТОЛОГИЯ",
    "НЕВРОЛОГИЯ",
    "НЕОНАТОЛОГИЯ",
    "ОТОЛАРИНГОЛОГИЯ",
    "ПЕДИАТРИЯ",
    "ПСИХИАТРИЯ",
    "РЕНТГЕНОЛОГИЯ",
    "ТРАВМАТОЛОГИЯ И ОРТОПЕДИЯ",
    "УЗИ",
    "УРОЛОГИЯ",
    "ХИРУРГИЯ",
    "ЭНДОСКОПИЯ",
    "ФАРМАЦИЯ",
  ];

  const icons = [
    Microscope,
    BriefcaseMedical,
    Brain,
    Syringe,
    SquareActivity,
    Heart,
    Pill,
    Stethoscope,
    PillBottle,
  ];

  const reviews = [
    {
      name: "Милана В.",
      role: "Студентка медицинского факультета",
      text: 'Благодарю проект "Наставничество" и свою наставницу Дацирхоеву Оксану Мухамедовну за плодотворное взаимодействие, полученные знания и приобретенные навыки. Оксана Мухамедовна познакомила меня основными методами функциональной диагностики, помогла улучшить знания в электрокардиографии и эхокардиографии, освоить СМАД и Холтер. Несомненно, полученный опыт поможет мне как в учебе, так и в будущем в моей профессии!',
      rating: 5,
    },
    {
      name: "Дарина С.",
      role: "Ординатор",
      text: "Участие в проекте «Наставничество» позволило мне углубить свои знания в области офтальмологии, изучить практические навыки, и методы диагностики, необходимые для данной профессии. Также проект дал мне возможность побывать на ряде операций ведущих офтальмохирургов России, в том числе и моего наставника. Во время операций мне пошагово объясняли все их этапы, детали и отвечали на все возникающие вопросы, чему я была очень рада. Благодарю наставничество за полученный опыт и знания!",
      rating: 5,
    },
    {
      name: "Артур К.",
      role: "Студент 5 курса",
      text: "Во время проекта у меня было продуктивное и максимально приятное взаимодействие со своим наставником. Даже поспе окончания проекта мы планируем продолжать взаимодействовать и поддерживать связь друг с другом. Надеюсь, что наша деятельность в рамках проекта послужит толчком для последующего развития в профессии и становления каждого как ведущего специалиста в своей сфере.",
      rating: 5,
    },
    {
      name: "Марина В.",
      role: "Выпускница программы",
      text: "Благодаря проекту я получила ценный опыт, который смогу использовать в дальнейшем своём пути. Проект стал для меня отличной возможностью развить свои слабые стороны и ещё больше вдохновиться своей будущей профессией. Не могу не отметить своего наставника - Семенова Жанна Борисовна поделилась со мной своими знаниями и тонкостями своей профессии, которые очень помогут мне в медицинской практике. За совместное время работы, я поняла, как важно быть грамотным профессионалом своего дела и знать многие тонкости.",
      rating: 5,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-25 font-sans">
      <section className="relative bg-green-700 text-white py-60 md:py-56">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src={
              "https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&w=1920&q=80"
            }
            alt=""
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <AnimatedSection>
          <div className="container mx-auto px-4 text-center relative z-10">
            <h1 className="text-3xl text-green-100 font-bold mb-6">МЕДИЦИНА</h1>
          </div>
          {icons.map((Icon, index) => (
            <IconWrapper
              key={index}
              style={{
                animationDelay: `${-index * (30 / icons.length)}s`,
                transform: `rotate(${
                  index * (360 / icons.length)
                }deg) translateX(150px) rotate(-${
                  index * (360 / icons.length)
                }deg)`,
              }}
            >
              <Icon className="text-green-200 opacity-70 w-[40px] h-[40px]" />
            </IconWrapper>
          ))}
        </AnimatedSection>
      </section>

      <ScrollingBanner>
        <ScrollingText>
          {`${medicalFields.join("   •   ")}   •   `.repeat(3)}
        </ScrollingText>
      </ScrollingBanner>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto mt-8">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-green-800 mb-8 md:mb-16">
            КОНЦЕПЦИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <Stethoscope className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В рамках сферы «Медицина» наставляемые получают уникальные
              прикладные навыки и знания через коммуникацию с опытными врачами
              различных специальностей, обладающими богатым профессиональным
              опытом в данной сфере.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <Brain className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Наставничество осуществляется через личное взаимодействие
              специалиста и студента. Наставник делится профессиональным опытом,
              проводит лекции на актуальные темы, организует посещения
              клинических баз и решение практико-ориентированных медицинских
              кейсов.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <div className="flex jusitfy-center items-center">
              <Heart className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            </div>
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В ходе проекта наставляемые закрепляют теоретические знания,
              изучают специфику медицинских направлений, приобретают
              практические навыки, участвуют в реальных медицинских процедурах и
              операциях, выстраивают понимание профессионального развития и
              создают сети для дальнейшего сотрудничества.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-green-800 mb-8 md:mb-16">
            ЭФФЕКТ ПРОГРАММЫ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Практические навыки среднего медперсонала и врача, насмотренность
              в диагностике пациентов, насмотренность в проведении операций,
              навыки оформления историй болезни.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Стажировка и возможность трудоустройства в ведущие медицинские
              центры.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Написание научных статей, исследовательских работ совместно с
              наставником, либо под его руководством.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Рекомендации от наставника для дальнейшего трудоустройства.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-green-800 mb-8 md:mb-16">
            УРОВЕНЬ ОБРАЗОВАНИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <School className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              4 курс СПО
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <University className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              4-6 курс специалитета
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <GraduationCap className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-green-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Ординатура
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-8 px-1 max-w-6xl mx-auto mb-4">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-green-800 mb-8 md:mb-16">
            НАПРАВЛЕНИЯ
          </h2>
          <AnimatedBranchTree
            mainField={medicalFields[0]}
            subFields={medicalFields.slice(1)}
            color={"green"}
          />
        </section>
      </AnimatedSection>

      <section className="py-8 lg:my-12">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-green-800 mb-16 md:mb-24">
              НАСТАВНИКИ ПРОШЛОГО ЗАПУСКА
            </h2>
          </AnimatedSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-x-4 lg:gap-y-20 justify-items-center">
            {[
              {
                name: "Семенова Жанна Борисовна",
                sphere:
                  "Доктор медицинских наук, главный внештатный детский нейрохирург г. Москвы и Центрального федерального округа",
                image: "nastav_semenova.webp",
              },
              {
                name: "Пшихачев Ахмедхан Мухамедович",
                sphere:
                  "Онкоуролог, доктор медицинских наук, старший научный сотрудник МНОЦ МГУ им. М.В. Ломоносова",
                image: "nastav_pshihachev.webp",
              },
              {
                name: "Долов Залим Олегович",
                sphere:
                  'Врач стоматолог-хирург-имплантолог, владелец сети стоматологических клиник "Доктор Дол"',
                image: "nastav_dolov.webp",
              },
              {
                name: "Сонова Марина Мусабиевна",
                sphere:
                  "Доктор медицинских наук, руководитель отделения акушерства и гинекологии Ильинской больницы",
                image: "nastav_sonova.webp",
              },
            ].map((mentor, index) => (
              <AnimatedSection key={mentor.name} className={`text-center`}>
                <img
                  src={mentor.image}
                  alt={mentor.name}
                  className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                />
                <h3 className="text-xl font-bold text-green-800 mb-2">
                  {mentor.name}
                </h3>
                <p className="text-lg text-gray-600 mb-2 md:px-28">
                  {mentor.sphere}
                </p>
              </AnimatedSection>
            ))}
          </div>
        </div>
      </section>

      <Reviews
        reviews={reviews}
        textSize={"base"}
        textHeight={"[23rem]"}
        titleColor={"green"}
      />

      <JoinTelegramSection />

      <JoinProjectSection />
    </div>
  );
}
