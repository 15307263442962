import React, { useRef, useEffect } from "react";
import { useInView, motion } from "framer-motion";

const AnimatedSection = ({
  children,
  direction = "up",
  className,
  onClick,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });

  const variants = {
    hidden: {
      opacity: 0,
      y: direction === "up" ? 60 : 0,
      x: direction === "left" ? -60 : direction === "right" ? 60 : 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
      transition: { duration: 0.8 },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
      className={className}
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedSection;
