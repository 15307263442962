import React from "react";
import AnimatedBackground from "../components/AnimatedBackground";

export default function About() {
  return (
    <AnimatedBackground>
      <div className="min-h-screen bg-gray-25 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Информация о проекте
          </h1>

          <div className="bg-gray-50 shadow-md rounded-lg p-6 mb-8 text-left text-justify">
            <h2 className="text-xl font-semibold mb-4">"ШАГ В БУДУЩЕЕ"</h2>
            <p className="mb-4">
              Проект заключается в организации площадки взаимодействия между
              инициативными студентами и современными специалистами из разных
              сфер, включая Медицину, Юриспруденцию, Экономику и Бизнес, PR&SMM,
              IT-программирование.
            </p>
            <h3 className="text-lg font-semibold mb-2">ЦЕЛЬ ПРОЕКТА</h3>
            <p className="mb-4">
              Личностная и профессиональная самореализация студенческой молодежи
              в различных сферах деятельности посредством организации
              эффективного взаимодействия наставнических пар.
            </p>
          </div>
        </div>
      </div>
    </AnimatedBackground>
  );
}
