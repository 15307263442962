import React, { useState, useEffect } from "react";
import { Star } from "lucide-react";
import AnimatedSection from "../components/AnimatedSection";

export default function Reviews(props) {
  const { reviews, textSize, textHeight } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      setProgress(0);
    }, 10000);

    const progressTimer = setInterval(() => {
      setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
    }, 100);

    return () => {
      clearInterval(timer);
      clearInterval(progressTimer);
    };
  }, []);

  const getReviewIndex = (index) => {
    return (index + reviews.length) % reviews.length;
  };

  const renderReview = (review, isCenter) => (
    <div
      className={`bg-white p-6 rounded-lg shadow-md transition-all duration-500 ${
        isCenter ? "transform scale-105 opacity-100" : "scale-90 opacity-50"
      }`}
    >
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mr-4">
          <span className="text-green-800 text-xl font-bold">
            {review.name.charAt(0)}
          </span>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-left">{review.name}</h3>
          <p className="text-sm text-gray-600 text-left">{review.role}</p>
        </div>
      </div>
      <p
        className={`text-${textSize} text-gray-700 mb-4 text-left text-justify w-[17rem] md:w-[22rem] lg:w-88 h-[29rem] md:h-[20rem] overflow-hidden`}
      >
        {review.text}
      </p>
      <div className="flex justify-start">
        {[...Array(review.rating)].map((_, i) => (
          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
        ))}
      </div>
    </div>
  );

  return (
    <AnimatedSection>
      <section className="py-8 px-4 max-w-7xl mx-auto mb-4">
        <h2
          className={`text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-${props.titleColor}-800 mb-8 md:mb-16`}
        >
          НАШИ ОТЗЫВЫ
        </h2>
        <div className="relative overflow-hidden">
          <div className="flex justify-center items-center space-x-8 w-full w-7xl mx-auto">
            {renderReview(reviews[getReviewIndex(currentIndex - 1)], false)}
            {renderReview(reviews[currentIndex], true)}
            {renderReview(reviews[getReviewIndex(currentIndex + 1)], false)}
          </div>
          <div className="w-1/2 md:w-1/4 h-1 bg-gray-200 mt-16 rounded-full overflow-hidden mx-auto">
            <div
              className="h-full bg-gray-400 transition-all duration-50 ease-linear"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </section>
    </AnimatedSection>
  );
}
