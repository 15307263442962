import React from "react";
import AnimatedBackground from "../components/AnimatedBackground";

export default function Legal() {
  return (
    <AnimatedBackground>
      <div className="min-h-screen bg-gray-25 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Политика конфиденциальности
          </h1>

          <div className="bg-gray-50 shadow-md rounded-lg p-6 text-left text-justify">
            <p className="mb-4">
              Мы ценим вашу приватность и стремимся защитить вашу личную
              информацию. Эта политика конфиденциальности объясняет, какую
              информацию мы собираем и как мы ее используем.
            </p>
            <h3 className="text-lg font-semibold mb-2">
              Собираемая информация
            </h3>
            <p className="mb-4">
              Мы собираем следующую информацию от пользователей нашего сайта:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Имя</li>
              <li>Фамилия</li>
              <li>Адрес электронной почты</li>
              <li>Номер телефона</li>
              <li>Пароль (в зашифрованном виде)</li>
            </ul>
            <h3 className="text-lg font-semibold mb-2">
              Использование информации
            </h3>
            <p className="mb-4">
              Собранная информация используется исключительно для целей проекта
              "ШАГ В БУДУЩЕЕ" и не передается третьим лицам. Мы используем эту
              информацию для:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Создания и управления вашим аккаунтом</li>
              <li>Связи с вами по вопросам, связанным с проектом</li>
              <li>Улучшения нашего сервиса и пользовательского опыта</li>
            </ul>
            <h3 className="text-lg font-semibold mb-2">Защита информации</h3>
            <p className="mb-4">
              Мы принимаем все необходимые меры для защиты вашей личной
              информации. Ваши данные хранятся на защищенных серверах и доступны
              только авторизованному персоналу проекта.
            </p>
            <h3 className="text-lg font-semibold mb-2">Ваши права</h3>
            <p className="mb-4">
              Вы имеете право запросить доступ к своей личной информации, ее
              исправление или удаление. Если у вас есть вопросы или возражения
              относительно нашей политики конфиденциальности, пожалуйста,
              свяжитесь с нами.
            </p>
            <p>
              Используя наш сайт, вы соглашаетесь с условиями этой политики
              конфиденциальности.
            </p>
          </div>
        </div>
      </div>
    </AnimatedBackground>
  );
}
