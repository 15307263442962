import React from "react";

export default function LoadingSpinner({ asOverlay = false }) {
  return (
    <div
      className={`inline-block ${
        asOverlay
          ? "fixed inset-0 flex items-center justify-center bg-black/30 z-50"
          : ""
      }`}
    >
      <div className="w-16 h-16 relative">
        <div className="w-16 h-16 rounded-full animate-spin absolute border-4 border-solid border-green-700 border-t-transparent"></div>
      </div>
    </div>
  );
}
