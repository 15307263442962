import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, User, Mail, Phone, Lock } from "lucide-react";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import env from "../env";
import { useDispatch } from "react-redux";
import ButtonLoadingSpinner from "../components/ButtonLoadingSpinner";
import OTPModal from "../components/OTPModal";
import { useHttpClient } from "../shared/hooks/http-hook";
import { uiActions } from "../store/ui-slice";

const Auth = () => {
  const { sendRequest } = useHttpClient();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);

  const spheres = [
    "Медицина",
    "Юриспруденция",
    "Экономика и бизнес",
    "IT-программирование",
    "PR & SMM",
  ];

  const handleSubmit = () => {
    sendOtp();
  };

  const [registerName, setRegisterName] = useState("");
  const [registerSurname, setRegisterSurname] = useState("");
  const [registerSphere, setRegisterSphere] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerLegal, setRegisterLegal] = useState(false);

  const [loginEmail, setLoginEmail] = useState();
  const [loginPassword, setLoginPassword] = useState();

  const [otpIsSentWindow, setOtpIsSentWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const loginHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const responseData = await sendRequest(
        env.BASE_URL + "/api/users/login",
        "POST",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          email: loginEmail,
          password: loginPassword,
        })
      );

      dispatch(
        uiActions.login({
          user: {
            id: responseData.userId,
            name: responseData.name,
            surname: responseData.surname,
            sphere: responseData.sphere,
            email: responseData.email,
            phoneNumber: responseData.phoneNumber,
            token: responseData.token,
          },
        })
      );

      setSuccessMessage("Logged In Successfully, redirecting...");

      // setTimeout(() => {
      //   window.location.assign("/auth");
      // }, 1000);
    } catch (error) {
      setError("An error occurred during log in");
    } finally {
      setIsLoading(false);
    }
  };

  const [formIsValid, setFormIsValid] = useState(false);

  //   Function to check if all required fields are filled
  const checkFormValidity = () => {
    const requiredFields = [
      registerName,
      registerSurname,
      registerSphere,
      registerEmail,
      registerPhoneNumber,
      registerPassword,
    ];

    const allFieldsFilled =
      requiredFields.every((field) => field !== "") && registerLegal;

    console.log(allFieldsFilled);

    if (!allFieldsFilled) {
      setFormIsValid(false);
      setError("Заполните все поля!");
      return;
    }

    if (registerPassword.length <= 6) {
      setError("Слишком короткий пароль!");
      return;
    }

    setFormIsValid(true);

    return true;
  };

  const [OTPmodalOpen, setOTPModalOpen] = useState(false);
  const handleClose = () => setOTPModalOpen(false);

  const sendOtp = async (e) => {
    e.preventDefault();

    const valid = checkFormValidity();

    if (!valid) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const responseData = await sendRequest(
        env.BASE_URL + "/api/users/send-otp",
        "POST",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          email: registerEmail,
        })
      );

      if (responseData.success) {
        setOTPModalOpen(true);
        setOtpIsSentWindow(true);
        setTimeout(() => {
          setOtpIsSentWindow(false);
        }, 600 * 1000); // OTP sent for 10 minutes
      } else {
        setError(responseData.message);
      }
    } catch (error) {
      setError("Возникла ошибка при отправке кода OTP.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url('/authback2.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-20"></div>
        <AnimatedSection className="min-h-screen bg-gray-25 flex items-center justify-center p-4 sm:p-6 lg:p-8 relative z-10">
          <div className="bg-white bg-opacity-90 rounded-lg shadow-xl p-6 sm:p-8 w-full max-w-md border border-gray-200">
            <div className="flex justify-center mb-8">
              <button
                className={`px-4 py-2 rounded-l-md flex-1 ${
                  isLogin ? "bg-green-500 text-white" : "bg-gray-200"
                }`}
                onClick={() => setIsLogin(true)}
              >
                Вход
              </button>
              <button
                className={`px-4 py-2 rounded-r-md flex-1 ${
                  !isLogin ? "bg-green-500 text-white" : "bg-gray-200"
                }`}
                onClick={() => setIsLogin(false)}
              >
                Регистрация
              </button>
            </div>
            <AnimatePresence mode="wait">
              <motion.form
                key={isLogin ? "login" : "register"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                onSubmit={handleSubmit}
                className="space-y-4"
              >
                {isLogin && (
                  <>
                    <div className="flex flex-col sm:space-x-4">
                      <div className="mb-4 sm:mb-0">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 text-left"
                        >
                          Email
                        </label>
                        <div className="mt-1 mb-4 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Mail className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            required
                            className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                            placeholder="you@example.com"
                            onChange={(e) => {
                              setLoginEmail(e.target.value);
                            }}
                          />
                        </div>

                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700 text-left"
                        >
                          Пароль
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Lock className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            required
                            className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                            placeholder="Минимум 6 символов"
                            onChange={(e) => {
                              setLoginPassword(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={loginHandler}
                      disabled={isLoading}
                      className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      ВОЙТИ
                    </button>
                  </>
                )}

                {!isLogin && (
                  <>
                    <div className="flex flex-col sm:flex-row sm:space-x-4">
                      <div className="flex-1 mb-4 sm:mb-0">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 text-left"
                        >
                          Имя
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <User className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                            placeholder="Иван"
                            onChange={(e) => {
                              setRegisterName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <label
                          htmlFor="surname"
                          className="block text-sm font-medium text-gray-700 text-left"
                        >
                          Фамилия
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <User className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="text"
                            name="surname"
                            id="surname"
                            required
                            className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                            placeholder="Иванов"
                            onChange={(e) => {
                              setRegisterSurname(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="sphere"
                        className="block text-sm font-medium text-gray-700 text-left"
                      >
                        Направление
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <select
                          name="sphere"
                          id="sphere"
                          required
                          className="focus:ring-green-500 focus:border-green-500 block w-full pl-3 pr-10 py-3 text-base border-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md appearance-none"
                          onChange={(e) => {
                            setRegisterSphere(e.target.value);
                          }}
                        >
                          <option value="">Выберите направление</option>
                          {spheres.map((sphere) => (
                            <option key={sphere} value={sphere}>
                              {sphere}
                            </option>
                          ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                          <ChevronDown className="h-4 w-4 text-gray-400" />
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 text-left"
                      >
                        Email
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Mail className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                          placeholder="you@example.com"
                          onChange={(e) => {
                            setRegisterEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 text-left"
                      >
                        Номер телефона
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Phone className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          required
                          className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                          placeholder="+7 (999) 999-99-99"
                          onChange={(e) => {
                            setRegisterPhoneNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700 text-left"
                      >
                        Пароль
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Lock className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          required
                          minLength={6}
                          className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-3 py-3 sm:text-sm border-gray-400 rounded-md"
                          placeholder="Минимум 6 символов"
                          onChange={(e) => {
                            setRegisterPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex gap-x-2">
                      <div className="flex justify-center align-center relative">
                        <input
                          className="appearance-none peer bg-white h-5 w-5 m-1 cursor-pointer transition-all rounded shadow hover:shadow-md border border-slate-300 checked:bg-green-600 checked:border-green-600"
                          type="checkbox"
                          name="legal"
                          id="legal"
                          required
                          checked={registerLegal}
                          onChange={(e) => setRegisterLegal(e.target.checked)}
                        />
                        <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="1"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <label
                        htmlFor="legal"
                        className="block text-xs md:text-sm font-medium text-gray-700 text-left flex items-center"
                      >
                        Я принимаю{" "}
                        <a
                          className="ml-1 text-blue-700 underline"
                          href={"/legal"}
                        >
                          политику конфидециальности
                        </a>
                      </label>
                    </div>

                    {isLoading && <ButtonLoadingSpinner />}
                    {!isLoading && (
                      <>
                        <div>
                          {error && (
                            <div className="w-full flex justify-center items-justify my-4 ">
                              <p className="error text-red-600">{error}</p>
                            </div>
                          )}
                          {!otpIsSentWindow && (
                            <button
                              type="submit"
                              onClick={sendOtp}
                              disabled={isLoading}
                              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                              ЗАРЕГИСТРИРОВАТЬСЯ
                            </button>
                          )}
                          {otpIsSentWindow && (
                            <button
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                setOTPModalOpen(true);
                              }}
                              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                              Введите OTP-код
                            </button>
                          )}
                        </div>
                      </>
                    )}
                    <OTPModal
                      open={OTPmodalOpen}
                      onClose={handleClose}
                      userCredentials={{
                        name: registerName,
                        surname: registerSurname,
                        sphere: registerSphere,
                        email: registerEmail,
                        phoneNumber: registerPhoneNumber,
                        password: registerPassword,
                      }}
                    />
                  </>
                )}
              </motion.form>
            </AnimatePresence>
          </div>
        </AnimatedSection>
      </div>
    </>
  );
};

export default Auth;
