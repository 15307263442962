import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";

const AnimatedButton = ({
  width,
  height,
  borderRadius,
  fontSize,
  margin,
  href,
  variant,
  backgroundOpacity,
  children,
}) => {
  const buttonRef = useRef(null);
  const spotlightRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;
    const spotlight = spotlightRef.current;

    button.addEventListener("mousemove", (evt) => {
      const movX = evt.clientX - button.getBoundingClientRect().x;
      gsap.to(spotlight, {
        x: movX,
        scale: 15,
        duration: 0.35,
      });
    });

    button.addEventListener("mouseleave", (evt) => {
      const movX = evt.clientX - button.getBoundingClientRect().x;
      gsap.to(spotlight, {
        x: movX,
        scale: 0,
        duration: 0.35,
      });
    });

    return () => {
      button.removeEventListener("mousemove", () => {});
      button.removeEventListener("mouseleave", () => {});
    };
  }, []);

  const buttonStyle = {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px",
    margin: margin || "8px 4px",
    borderRadius: borderRadius || "23.5px",
    overflow: "hidden",
    textDecoration: "none",
    background: backgroundOpacity
      ? `rgba(10, 90, 73, ${backgroundOpacity})`
      : "white",
    opacity: "95%",
    border:
      variant === "green"
        ? "2px solid rgb(28, 190, 28)"
        : variant === "blue"
        ? "2px solid #2196F3"
        : "none",
  };

  const buttonWrapperStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 1rem",
    width: width || "16rem",
    height: height || "3rem",
    borderRadius: borderRadius || "24px",
    background: "transparent",
  };

  const buttonTextStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    fontFamily: "sans-serif",
    margin: "1rem 0",
    fontWeight: 700,
    fontSize: fontSize || "18px",
    color:
      variant === "green"
        ? "rgb(28, 210, 28)"
        : variant === "blue"
        ? "#2196F3"
        : "#818cf8",
    transition: "300ms ease",
  };

  const buttonSpotlightStyle = {
    position: "absolute",
    zIndex: 1,
    height: "10px",
    width: "10px",
    opacity: 1,
    borderRadius: "50%",
    background:
      variant === "green"
        ? "linear-gradient(to right, rgb(28, 210, 28), #4d9e50)"
        : variant === "blue"
        ? "linear-gradient(to right, #2196F3, #58ACD1)"
        : "none",
    inset: 0,
    top: "50%",
    transform: "scale(0)",
  };

  return (
    <Link
      to={href}
      ref={buttonRef}
      style={buttonStyle}
      onMouseEnter={() => {
        if (variant === "green") {
          buttonRef.current.style.background =
            "linear-gradient(to right, rgb(28, 210, 28), #4d9e50)";
        } else if (variant === "blue") {
          buttonRef.current.style.background =
            "linear-gradient(to right, #2196F3, #58ACD1)";
        }
        textRef.current.style.color = "white";
      }}
      onMouseLeave={() => {
        buttonRef.current.style.background = backgroundOpacity
          ? `rgba(10, 90, 73, ${backgroundOpacity})`
          : "white";
        textRef.current.style.color =
          variant === "green"
            ? "rgb(28, 210, 28)"
            : variant === "blue"
            ? "#2196F3"
            : "#818cf8";
      }}
    >
      <span ref={spotlightRef} style={buttonSpotlightStyle} />
      <span style={buttonWrapperStyle}>
        <span ref={textRef} style={buttonTextStyle}>
          {children}
        </span>
      </span>
    </Link>
  );
};

export default AnimatedButton;
