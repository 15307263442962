// LOCAL
// const PORT = 5000;
// const BASE_URL = "http://localhost:" + PORT;

// PUBLIC VERCEL
const BASE_URL = "https://nastavnichestvo-backend.vercel.app";

// PUBLIC VPS-server
// const BASE_URL = "https://nastavnichestvo24.ru";

const CONTACT_MAIL = "t.urusov13@mail.ru";
const ADMINS_LIST = [
  "t.urusov13@mail.ru",
  "nirova-fatimka@mail.ru",
  "zbelbekova@mail.ru",
  "zakir_achokh@mail.ru",
];

const env = {
  BASE_URL,
  CONTACT_MAIL,
  ADMINS_LIST,
};

export default env;
