import React from "react";
import { Link } from "react-router-dom";
import AnimatedButton from "../components/AnimatedButton";
import env from "../env";
import { useSelector } from "react-redux";

const Header = () => {
  const user = useSelector((state) => state.ui.user);
  const isAdmin = user && user.email && env.ADMINS_LIST.includes(user.email);

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-2 py-1 flex justify-between items-center">
        <Link to={"/"} className="flex items-center">
          <img
            src="/logo.webp"
            alt="Эльбрус"
            className="rounded-full w-14 h-14 md:w-20 md:h-20"
          />
          <span className="ml-2 md:ml-4 text-lg md:text-2xl font-bold text-green-700">
            ШАГ В БУДУЩЕЕ
          </span>
        </Link>

        <div className="flex">
          {isAdmin && (
            <AnimatedButton
              href={"/admin"}
              variant="blue"
              width={"6rem"}
              height={"2.5rem"}
              borderRadius={"12px"}
              margin={"0.5rem"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              Админ
            </AnimatedButton>
          )}

          <AnimatedButton
            href={"/auth"}
            variant="green"
            width={"3.5rem"}
            height={"2.5rem"}
            borderRadius={"12px"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </AnimatedButton>
        </div>
      </nav>
    </header>
  );
};

export default Header;
