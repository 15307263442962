import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaSearch,
  FaFileExcel,
  FaCheckCircle,
  FaHourglassHalf,
  FaLock,
} from "react-icons/fa";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useDispatch, useSelector } from "react-redux";
import { loadUsers } from "../store/ui-slice";
import env from "../env";

export default function Admin() {
  const { sendRequest } = useHttpClient();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.ui.user);

  console.log(user);

  const [searchTerm, setSearchTerm] = useState("");
  const users = useSelector((state) => state.ui.users);

  useEffect(() => {
    dispatch(loadUsers(sendRequest));
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user?.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const usersToReview = users.filter(
    (user) =>
      (user?.formStageStatus === "completed" &&
        user?.formStageApproved !== true) ||
      (user?.testStageStatus === "completed" &&
        user?.testStageApproved !== true) ||
      (user?.interviewStageStatus === "completed" &&
        user?.interviewStageApproved !== true)
  );

  const usersWithInterviews = users.filter((user) => user?.interviewStageDate);

  const handleConfirmStage = async (userId, stage) => {
    try {
      await sendRequest(
        env.BASE_URL + `/api/users/${userId}/updateStages`,
        "PATCH",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          stageType: stage,
          stageApproved: true,
        })
      );

      dispatch(loadUsers(sendRequest));
    } catch (error) {}
  };

  const handleExportExcel = async () => {
    try {
      const responseData = await fetch(
        env.BASE_URL + "/api/users/getUsers/excel",
        {
          method: "GET",
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // Authorization: "Bearer " + user.token,
            // email: user.email,
          },
        }
      );

      const blob = await responseData.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "nastavnichestvo2024_users.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {}
  };

  const getStatusBadge = (status, approved, type) => {
    let bgColor, textColor, text;
    switch (status) {
      case "completed":
        if (approved) {
          bgColor = "bg-green-100";
          textColor = "text-green-800";
          text = `${type} подтверждено`;
          break;
        } else {
          bgColor = "bg-yellow-100";
          textColor = "text-yellow-800";
          text = `${type} на проверке`;
          break;
        }
      default:
        bgColor = "bg-gray-100";
        textColor = "text-gray-800";
        text = `${type} не пройдено`;
    }
    return (
      <span
        className={`px-2 py-1 rounded-full text-xs ${bgColor} ${textColor}`}
      >
        {text}
      </span>
    );
  };

  return (
    <div className="min-h-screen bg-gray-25 p-4 md:p-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-left">
          Панель администратора
        </h1>

        {/* Search bar */}
        <div className="mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Поиск пользователей..."
              className="w-full p-4 pr-12 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>

        {/* User cards */}
        <div className="mb-8 overflow-y-auto h-[50vh] bg-gray-50 rounded-lg shadow">
          {filteredUsers.map((user) => (
            <div
              key={user?.id}
              className="p-4 border-b border-gray-200 flex items-center"
            >
              <div className="flex-shrink-0 mr-4">
                <FaUser className="text-4xl text-gray-500" />
              </div>
              <div className="flex-grow text-left">
                <h3 className="text-lg font-semibold">
                  {user?.name} {user?.surname}
                </h3>
                <p className="text-gray-600">{user?.sphere}</p>
                <p className="text-gray-600">{user?.email}</p>
                <p className="text-gray-600">{user?.phoneNumber}</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {getStatusBadge(
                    user?.formStageStatus,
                    user?.formStageApproved,
                    "Форма"
                  )}
                  {getStatusBadge(
                    user?.testStageStatus,
                    user?.testStageApproved,
                    "Тест"
                  )}
                  {getStatusBadge(
                    user?.interviewStageStatus,
                    user?.interviewStageApproved,
                    "Собеседование"
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Excel export button */}
        <button
          onClick={handleExportExcel}
          className="mb-8 bg-green-500 text-white px-6 py-3 rounded-lg flex items-center hover:bg-green-600 transition-colors duration-300"
        >
          <FaFileExcel className="mr-2" />
          Выгрузить Excel файл
        </button>

        {/* Two-column table */}
        <div className="space-y-8">
          {/* Users to review column */}
          <div className="bg-gray-50 rounded-lg shadow p-4">
            <h2 className="text-2xl font-semibold mb-4 text-left">
              На проверке
            </h2>
            <div className="overflow-y-auto h-[50vh]">
              {usersToReview.length > 0 ? (
                usersToReview.map((user) => (
                  <div
                    key={user?.id}
                    className="mb-4 p-4 border border-gray-200 rounded-lg text-left"
                  >
                    <div className="flex items-center mb-2">
                      <FaUser className="text-2xl text-gray-500 mr-2" />
                      <div>
                        <h3 className="font-semibold">
                          {user?.name} {user?.surname}
                        </h3>
                        <p className="text-sm text-gray-600">{user?.email}</p>
                      </div>
                    </div>
                    <div className="mb-2 text-left">
                      {user?.formStageStatus === "completed" &&
                        user?.formStageApproved !== true && (
                          <p className="text-sm text-yellow-600">
                            Форма ожидает подтверждения
                          </p>
                        )}
                      {user?.testStageStatus === "completed" &&
                        user?.testStageApproved !== true && (
                          <p className="text-sm text-yellow-600">
                            Тест ожидает подтверждения
                          </p>
                        )}
                      {user?.interviewStageStatus === "completed" &&
                        user?.interviewStageApproved !== true && (
                          <p className="text-sm text-yellow-600">
                            Интервью ожидает подтверждения
                          </p>
                        )}
                    </div>
                    <button
                      onClick={() => {
                        let stage = "form";

                        if (user?.formStageApproved !== true) {
                          stage = "form";
                        } else if (user?.testStageApproved !== true) {
                          stage = "test";
                        } else if (user?.interviewStageApproved !== true) {
                          stage = "interview";
                        }

                        handleConfirmStage(user?.id, stage);
                      }}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-600 transition-colors duration-300"
                    >
                      Подтвердить этап
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-center text-lg text-gray-500">
                  Нет данных на проверку
                </p>
              )}
            </div>
          </div>

          {/* Interviews column */}
          <div className="bg-gray-50 rounded-lg shadow p-4">
            <h2 className="text-xl font-semibold mb-4 text-left">
              Собеседования
            </h2>
            <div className="overflow-y-auto h-[50vh]">
              {usersWithInterviews.length > 0 ? (
                usersWithInterviews.map((user) => (
                  <div
                    key={user?.id}
                    className="mb-4 p-4 border border-gray-200 rounded-lg"
                  >
                    <div className="flex items-center mb-2 text-left">
                      <FaUser className="text-2xl text-gray-500 mr-2" />
                      <div>
                        <h3 className="font-semibold">
                          {user?.name} {user?.surname}
                        </h3>
                        <p className="text-sm text-gray-600">{user?.email}</p>
                      </div>
                    </div>
                    {user?.interviewStageApproved && (
                      <p className="text-sm text-green-600 text-left">
                        Дата собеседования: {user?.interviewStageDate}
                      </p>
                    )}
                    {!user?.interviewStageApproved && (
                      <p className="text-sm text-blue-600 text-left">
                        Дата собеседования: {user?.interviewStageDate}
                      </p>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500">Здесь пока пусто</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
